import {  useParams } from 'react-router-dom';
import { useTrucksContext } from '../../context/TrucksProvider';
import { useEffect, useState } from 'react';
import { getCanCodes, getCanMalfunctionCodes } from '../../api/webfleet';
import { CanCodes, CanMalfCodes, ErrorCodeLevel, VehicleStatus } from '../../types/Webfleet';
import cn from 'classnames';

export const TruckDetailsPage = () => {
  const { truck, downloadTruck, setTruck, updateTruck } = useTrucksContext();
  const [ canCodes, setCanCodes ] = useState<CanCodes | undefined>(undefined);
  const [ canMalfCodes, setCanMalfCodes ] = useState<CanMalfCodes | undefined>(undefined);

  const { truckId } = useParams();
  // const navigate = useNavigate();


  useEffect(() => {
    if (!truckId) {
      return;
    }

    try {
      console.log(truckId);

      downloadTruck(truckId);

    } catch (e) {
      console.log('Problem getting truck: ', e);
    }

    const canCodes = async () => {
      try {
        const codes = await getCanCodes(truckId);
        setCanCodes(codes.data);
      } catch (e) {
        console.log('Problem getting can codes: ', e);
      }
    };

    const canMalCodes = async () => {
      try {
        const codes = await getCanMalfunctionCodes(truckId + '&malfunctionlevel=2');

        setCanMalfCodes(codes.data);
      } catch (e) {
        console.log('Problem getting malfunction can codes: ', e);
      }
    };
    canCodes();
    canMalCodes();
  }, [ ]);

  if (!truck) {
    return null;
  }
  console.log(canCodes);
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      await updateTruck(truck);
      
    } catch (e) {
      console.log('truck not updated');
    }
   

  };

  return (
    <form onSubmit={(e) => handleSubmit(e)}>
      <div className='space-y-12'>
        <div className='border-b border-gray-900/10 pb-12'>
          <h2 className='text-base font-semibold leading-7 text-gray-900'>
            Truck Details
          </h2>

          <div className='mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6'>

            <div className='col-span-full sm:col-span-2 '>
              <label
                htmlFor='first-name'
                className='block text-sm font-medium leading-6 text-gray-900'
              >
                Registration no.
              </label>
              <div className='mt-2'>
                <input
                  type='text'
                  name='first-name'
                  id='first-name'
                  value={truck.objectname}
                  required
                  onChange={(e) => {
                    setTruck({
                      ...truck,
                      objectname: e.target.value,
                    });
                  }}
                  className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-orange-600 hover:ring-orange-500 sm:text-sm sm:leading-6'
                />
              </div>
            </div>

            <div className='col-span-full sm:col-span-2'>
              <label
                htmlFor='last-name'
                className='block text-sm font-medium leading-6 text-gray-900'
              >
                GPS id
              </label>
              <div className='mt-2'>
                <input
                  type='text'
                  name='last-name'
                  id='last-name'
                  required
                  value={truck.obu_serialno}
                  disabled
                  onChange={(e) => {
                    setTruck({
                      ...truck,
                      obu_serialno: e.target.value,
                    });
                  }}
                  className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-orange-600 hover:ring-orange-500 sm:text-sm sm:leading-6'
                />
              </div>
            </div>
            {/* <span className='sm:col-span-2'></span> */}
            <div className='col-span-full sm:col-span-2'>
              <label
                htmlFor='street-address'
                className='block text-sm font-medium leading-6 text-gray-900'
              >
                Odometer
              </label>
              <div className='mt-2'>
                <input
                  type='text'
                  name='street-address'
                  id='street-address'
                  autoComplete='street-address'
                  required
                  value={truck.odometer}
                  onChange={(e) => {
                    setTruck({
                      ...truck,
                      odometer: +e.target.value,
                    });
                  }}
                  className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-orange-600 hover:ring-orange-500 sm:text-sm sm:leading-6'
                />
              </div>
            </div>

            <div className='col-span-1'>
              <label
                htmlFor='address-building'
                className='block text-sm font-medium leading-6 text-gray-900'
              >
                Fuel
              </label>
              <div className='mt-2'>
                <input
                  type='text'
                  name='address-building'
                  id='address-building'
                  value={truck.fuellevel ? truck.fuellevel / 10 + '%' : 0}
                  disabled
                  onChange={(e) => {
                    setTruck({
                      ...truck,
                      fuellevel: +e.target.value,
                    });
                  }}
                  className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-orange-600 hover:ring-orange-500 sm:text-sm sm:leading-6'
                />
              </div>
            </div>
            <div className='col-span-1'>
              <label
                htmlFor='address-apt'
                className='block text-sm font-medium leading-6 text-gray-900'
              >
                Speed
              </label>
              <div className='mt-2'>
                <input
                  type='text'
                  name='address-apt'
                  id='address-apt'
                  autoComplete='address-apt'
                  value={truck.speed ? truck.speed + 'km/h' : 0}
                  disabled
                  onChange={(e) => {
                    setTruck({
                      ...truck,
                      maxLoad: e.target.value,
                    });
                  }}
                  className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-orange-600 hover:ring-orange-500 sm:text-sm sm:leading-6'
                />
              </div>
            </div>
            <div className='col-span-full sm:col-span-1'>
              <label
                htmlFor='city'
                className='block text-sm font-medium leading-6 text-gray-900'
              >
                Floor area
              </label>
              <div className='mt-2'>
                <input
                  type='text'
                  name='city'
                  id='city'
                  value={truck.floorArea}
                  required
                  onChange={(e) => {
                    setTruck({
                      ...truck,
                      floorArea: e.target.value,
                    });
                  }}
                  className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-orange-600 hover:ring-orange-500 sm:text-sm sm:leading-6'
                />
              </div> 
            </div>

            <div className='col-span-full sm:col-span-1'>
              <label
                htmlFor='region'
                className='block text-sm font-medium leading-6 text-gray-900'
              >
                Max load
              </label>
              <div className='mt-2'>
                <input
                  type='text'
                  name='region'
                  id='region'
                  value={truck.maxLoad}
                  onChange={(e) => {
                    setTruck({
                      ...truck,
                      maxLoad: e.target.value,
                    });
                  }}                  className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-orange-600 hover:ring-orange-500 sm:text-sm sm:leading-6'
                />
              </div>
            </div>

            {/* <div className='sm:col-span-2'>
              <label
                htmlFor='postal-code'
                className='block text-sm font-medium leading-6 text-gray-900'
              >
                ZIP / Postal code
              </label>
              <div className='mt-2'>
                <input
                  type='text'
                  name='postal-code'
                  id='postal-code'
                  autoComplete='postal-code'
                  required
                  value={user.postCode}
                  onChange={(e) => {
                    setUser({
                      ...user,
                      postCode: e.target.value,
                    });
                  }}
                  className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-orange-600 hover:ring-orange-500 sm:text-sm sm:leading-6'
                />
              </div>
            </div> */}

            {/* <div className='sm:col-span-3'>
              <label
                htmlFor='company-vat'
                className='block text-sm font-medium leading-6 text-gray-900'
              >
                VAT or Company registration no.
              </label>
              <div className='mt-2'>
                <input
                  type='text'
                  name='company-vat'
                  id='company-vat'
                  value={user.VAT_EU}
                  required={user.company}
                  onChange={(e) => {
                    setUser({
                      ...user,
                      VAT_EU: e.target.value,
                    });
                  }}
                  className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-orange-600 hover:ring-orange-500 sm:text-sm sm:leading-6'
                />
              </div>
            </div> */}
            {canCodes && canMalfCodes && 
              <>
                <div className='sm:col-span-full'>
                  <label
                    htmlFor='company-vat'
                    className='block text-sm font-medium leading-6 text-gray-900'
                  >
       Can Codes
                  </label>

                  <div className='mt-2'>

                    {canCodes?.filter(code => code.cansignalvalue !== 0).sort((a, b) => b.cansignaltime.localeCompare(a.cansignaltime)).map((code, index) => (
                      <li key={index}>{code.cansignaltime} - {code.cansignaltypedescription}, value: {code.cansignalvalue === 1 ? 'TRUE' : code.cansignalvalue}</li>
                    ))}
                    {/* {activeUser?.role !== 'admin' && user.postPaid
         ? (<div>7 days</div>)
         : (<div>Pre-paid</div>)} */}
       
                  </div>
                </div> 

                <div className='sm:col-span-full'>
                  <label
                    htmlFor='company-vat'
                    className='block text-sm font-medium leading-6 text-gray-900'
                  >
       Can Malfuncion Codes
                  </label>

                  <div className='mt-2'>

                    {canMalfCodes?.filter(code => code.malfunctionlevel !== 0).map((code, index) => (
                      
                      <li key={index}>
                        
                        <dd className={cn('inline-flex ml-4 mb-3 items-center rounded-md bg-green-50 px-2 py-2 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20', {
                          'bg-yellow-50 px-2 py-1 text-xs font-medium text-yellow-800 ring-1 ring-inset ring-yellow-600/20': code.malfunctionlevel === 2,
                          'bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10': code.malfunctionlevel === 3
                        })}>
                          {VehicleStatus[code.malfunctiontype]}, {ErrorCodeLevel[code.malfunctionlevel]}
                        </dd>
                      </li>
                    ))}
                    {/* {activeUser?.role !== 'admin' && user.postPaid
         ? (<div>7 days</div>)
         : (<div>Pre-paid</div>)} */}
       
                  </div>
                </div> 
              </>

            }
       
          </div>
        </div>

      </div>

      {/* <div className='mt-6 flex items-center justify-end gap-x-6'>
        <button
          type='button'
          className='text-sm font-semibold leading-6 text-gray-900'
          onClick={() => navigate('/vans')}
        >
          Cancel
        </button>
        <button
          type='submit'
          className='rounded-md bg-orange-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600'
        >
          Save
        </button>
      </div> */}
    </form>
  );
};
