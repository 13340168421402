import { useEffect, useState } from 'react';
import { User } from '../types/User';
import { client } from '../services/axiosClient';
import { jwtDecode } from 'jwt-decode';


export const useAuth = () => {
  const [ activeUser, setActiveUser ] = useState<User | undefined>(undefined);
  const [ error, setError ] = useState<boolean>(false);
  const [ attempts, setAttempts ] = useState<boolean>(false);
  const [ logging, setLogging ] = useState<boolean>(false);
  const [ isAuthChecking, setIsAuthChecking ] = useState(true);

  
  useEffect(() => {

    const checkToken = async () => {
      const token = localStorage.getItem('veacctok');

      if (token) {
        try {
          const decodedUser = await jwtDecode(token);
          const currentTime = Date.now().valueOf() / 1000;
  
          // Check if the token has expired
          if (decodedUser.exp && decodedUser.exp < currentTime) {
            console.log('Token expired');
            await refresh();
            return false;
          }
          setActiveUser(decodedUser as User);
 
        } catch (error) {
          console.log(error);
          await refresh(); // Odśwież token, jeśli jest nieprawidłowy
        } finally {
          
          setIsAuthChecking(false);
        }
      } else {
        await refresh(); // Odśwież token, jeśli nie istnieje
      }
    };


    checkToken();

  }, []);
  

  const handleError = () => {
    setError(true);
    setTimeout(() => {
      setError(false);
    }, 3000);
  };

  const handleAttempt = () => {
    setAttempts(true);
    setTimeout(() => {
      setAttempts(false);
    }, 3000);
  };

  const userLogin = async (user: Partial<User>) => {
    setLogging(true);

    try {
      const response = await client.post('/login', user, {
        headers: {
          'Content-Type': 'application/json',
        },
        withCredentials: true,
      });

      if (response.status === 401) {
        handleError();
        return 'Unauthorized';
        
      }

      if (response.data === 'to many login attmpts') {
        handleAttempt();
        return 'to many login attempts';
      }
      if (!response.data) {
        handleError();
        console.log('Network response was not ok');
        
      }

      if (response.data) {

        await localStorage.setItem('veacctok', response.data.accessToken);

        setActiveUser(response.data.user);
        setIsAuthChecking(false);
        // ... rest of your success logic
      }
      
      
    } catch (error) {
      // console.error('Error:', error);
      handleError();
    } finally {
      // console.log('logged in:', isAuthenticated);
      setLogging(false);
      setIsAuthChecking(false);
    }

  };

  const userLogOut = async () => {
    
    await client.get('/logout', { withCredentials: true });
    setActiveUser(undefined);
    localStorage.removeItem('veacctok');
    // setLoggedInUser(undefined);
    // setIsAuthenticated(false);
  };

  const refresh = async () => {
    setIsAuthChecking(true);

    try {
      const response = await client.get('/refresh', { withCredentials: true });
 
      if (response.data) {

        localStorage.setItem('veacctok', response.data.accessToken);
        setActiveUser(response.data.user);
        // setIsAuthenticated(true);
      } else {
        // Handle the case where no new accessToken is returned

        setActiveUser(undefined);
        // setIsAuthenticated(false);
      }
    } catch (error) {

      setActiveUser(undefined);
      // setIsAuthenticated(false);
    } finally {
      setIsAuthChecking(false);
    }
    
  };

  return {
    isAuthChecking,
    userLogin,
    attempts, logging, error, userLogOut, refresh,
    activeUser,
    setActiveUser,
  };
};