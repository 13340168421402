import { useState } from 'react';
import cn from 'classnames';
import { User } from '../../../types/User';
import { useFetchAllPayers } from '../../../hooks/useUsers';

export const AutoFillClients = () => {
  const [ text, setText ] = useState('');
  // const [ debouncedText, setDebouncedText ] = useState(text);
  // const [ showClients, setShowClients ] = useState<Array<User> | []>([]);
  const [ searchBoxFocus, setSearchBoxFocus ] = useState<boolean>(false);
  const [ activeIndex, setActiveIndex ] = useState(0);

  const { clients } = useFetchAllPayers();
  const handleSetClient = (result: User) => {
    if (clients) {
      // const result = clients.find(client => client.id.toString() === id);

      if (!result) {
        return;
      }
      // setActiveClient(result);

      // setOrderer(result);
      setText(`${result?.firstName} ${result?.lastName}${result?.companyName ? ' | ' + result.companyName : ''}`);
    } 
  };

  const handleBlur = () => {
    setTimeout(() => {
      setSearchBoxFocus(false);
    }, 300);
    
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (!clients) {
      return;
    }

    if (e.key === 'ArrowDown') {
      e.preventDefault(); // Prevent the default action (scrolling) on arrow down
      setActiveIndex(prevIndex => (prevIndex < clients.length - 1 ? prevIndex + 1 : prevIndex));
    } else if (e.key === 'ArrowUp') {
      e.preventDefault(); // Prevent the default action (scrolling) on arrow up
      setActiveIndex(prevIndex => (prevIndex > 0 ? prevIndex - 1 : 0));
    } else if (e.key === 'Enter') {
      e.preventDefault();
      const activeItem = clients[activeIndex];
      if (activeItem) {
        handleSetClient(activeItem);
        // setSearchBoxFocus(false); // Optionally close the list after selection
        setActiveIndex(0);
      }
    }

  };

  const handleFocus = () => {
    setSearchBoxFocus(true);
  };

  // useEffect(() => {
  //   const handler = setTimeout(() => {
  //     setDebouncedText(text);
  //   }, 300);

  //   return () => {
  //     clearTimeout(handler);
  //   };
  // }, [ text ]);

  // useEffect(() => {

  //   const getClientsData = async () => {

  //     try {
  //       const response = await getClients(`search=${text}`);

  //       if (!response) {
  //         return;
  //       }
  //       setClients(response.data.rows);
  //     } catch (e) {
  //       console.error(e);
  //     }
  //   };

  //   if (debouncedText) {
  //     getClientsData();
  //   }
  // }, [ debouncedText ]);
 
  // 
  return (

    <div className='grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-5'>
      <div>
        <h2 className='text-base font-semibold leading-7 text-gray-900'>
      Orderer
        </h2>

        <p className='mt-1 text-sm leading-6 text-gray-600'>
      Choose orderer.
        </p>
      </div>
      <div className='grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-4'>
        <div className='sm:col-span-4'>
          <label
            htmlFor='receiver-company'
            className='block text-sm font-medium leading-6 text-gray-900'
          >
      Search
          </label>

          <div  className='mt-2 relative'>
            <input
              onBlur={handleBlur}
              onFocus={handleFocus}
              id='receiver-company'
              name='receiver-company'
              type='text'
              value={text}
              onKeyDown={e => handleKeyDown(e)}
              placeholder='Type client name, surname, or company name'
              autoComplete='organization'
              onChange={(e) => setText(e.target.value)}
              className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset hover:ring-orange-500 focus:ring-orange-600 sm:text-sm sm:leading-6'
            />
            {searchBoxFocus && text.length > 1 && clients && clients.length > 0 &&
            <ul  className='absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm cursor-pointer'>
              {clients.map((result, index) => {
       
                return (
                  <li
                    onClick={() => handleSetClient(result)}
                    key={result.id}
                    className={cn('pl-3 hover:bg-orange-600/50', {
                      'bg-orange-600/50': index === activeIndex
                    })}>{result.firstName} {result.lastName} {result.companyName ? result.companyName : ''}</li>
                );
              }) }
            </ul>
            }
    
          </div>
        </div>
      </div>
    </div>
  );
};
