import axios from 'axios';

// Create an Axios instance for API requests
export const apiClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL, // Replace with your API base URL
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json', // Adjust content type as needed
  },
});

// Axios interceptor to add the auth token to each request
apiClient.interceptors.request.use(
  config => {
    const token = localStorage.getItem('veacctok');

    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  
  }
);

// Create a separate Axios instance for static requests, if needed
export const client = axios.create({
  baseURL: process.env.REACT_APP_STATIC_URL,
  // Other configurations as needed
});

client.interceptors.request.use(
  config => {
    const token = localStorage.getItem('veacctok');

    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    
    return config;
  },
  error => {
    return Promise.reject(error);
  
  }
);

export const tomtomClient = axios.create({
});
