import { apiClient } from '../services/axiosClient';
import { Contact } from '../types/Order';

export type GetContactsReturnData = {
  rows: Contact[]
  count: number
}
const getContacts = async (params: string
): Promise<GetContactsReturnData> => {
  console.log(params);
  try {
    const { data } =  await apiClient.get('/contacts?' + params);

    return data;

    
  } catch (error) {
    throw new Error('Problem downloading contacts');
  }
 
};


export const contactsService = {
  getContacts
};