import { PackageList } from './Package';

export const Packages = () => {

  return (
    <div className='grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-5'>
      <div>
        <h2 className='text-base font-semibold leading-7 text-gray-900'>
        Packages
        </h2>
        <p className='mt-1 text-sm leading-6 text-gray-600'>
        Please add dimenstions, weight and quantities of all packages.
        </p>
      </div>

      <PackageList/>

    </div>
  );
};