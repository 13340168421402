import { useNavigate } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { useFetchUser, useUpdateUser } from '../../hooks/useUsers';
// import { User } from '../../types/User';
import { useEffect } from 'react';
import { useGetCountries } from '../../hooks/useCountries';

type FormData = {
  id: number,
  email: string,
  company: string,
  companyName: string,
  firstName: string,
  lastName: string,
  countryName: string,
  street: string,
  building: string,
  apt: string,
  city: string,
  postCode: string,
  VAT_EU: string
}

export const UserPage = () => {
  // const { activeUser } = useAuthContext();
  const navigate = useNavigate();
  const { user } = useFetchUser();
  const { updateUser } = useUpdateUser();
  const { countries, isLoading } = useGetCountries();

  const { register, handleSubmit, formState: { errors }, watch, reset } = useForm<FormData>({
    defaultValues: user ? {
      id: user.id,
      email: user.email,
      company: user.company ? 'notPrivate' : 'private',
      companyName: user.companyName,
      firstName: user.firstName,
      lastName: user.lastName,
      countryName: user.country?.countryName || '',
      street: user.street,
      building: user.building,
      apt: user.apt,
      city: user.city,
      postCode: user.postCode,
      VAT_EU: user.VAT_EU
    } : {}
  });
  useEffect(() => {
    if (user) {
      reset({ ...user, countryName: user.country?.countryName || 'Choose country', company: user.company ? 'notPrivate' : 'private' });
    }
  }, [ user ]);


  const onSubmit: SubmitHandler<FormData> = async (data) => {
  

    if (!data || isLoading) {
      return;
    }
    
    console.log('submitted data: ', data);


    updateUser({ ...data, company: watch('company') === 'notPrivate' });

    // try {
    //   await updateUser({
    //     ...data,
    //     company: data.company === 'notPrivate'
    //   });
      
    // } catch (e) {
    //   console.log('user not updated');
    // }
   

  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className='space-y-12'>
        <div className='border-b border-gray-900/10 pb-12'>
          <h2 className='text-base font-semibold leading-7 text-gray-900'>
            Your Profile
          </h2>
          <p className="mt-1 text-sm leading-6 text-gray-600">Your information is required to ensure the accurate calculation of VAT for transport services.</p>

          <fieldset className='mt-10'>
            <legend className='text-sm font-semibold leading-6 text-gray-900'>
              Are you private person or company?
            </legend>
            {/* <p className="mt-1 text-sm leading-6 text-gray-600">These are delivered via SMS to your mobile phone.</p> */}
            <div className='mt-6 space-y-6'>
              <div className='flex items-center gap-x-3'>
                <input
                  {...register('company')}
                  id='private-person'
                  type='radio'
                  value='private'
                  className='h-4 w-4 border-gray-300 text-orange-600 focus:ring-orange-600 hover:ring-orange-500'
                />
                <label
                  htmlFor='private-person'
                  className='block text-sm font-medium leading-6 text-gray-900'
                >
                  Private person
                </label>
              </div>
              <div className='flex items-center gap-x-3'>
                <input
                  {...register('company')}
                  id='notPrivate'
                  type='radio' 
                  value='notPrivate'
                  className='h-4 w-4 border-gray-300 text-orange-600 focus:ring-orange-600 hover:ring-orange-500'
                />
                <label
                  htmlFor='notPrivate'
                  className='block text-sm font-medium leading-6 text-gray-900'
                >
                  Company
                </label>
              </div>
              {/* <div className="flex items-center gap-x-3">
                <input
                  id="push-nothing"
                  name="push-notifications"
                  type="radio"
                  className="h-4 w-4 border-gray-300 text-orange-600 focus:ring-orange-600 hover:ring-orange-500"
                />
                <label htmlFor="push-nothing" className="block text-sm font-medium leading-6 text-gray-900">
                    No push notifications
                </label>
              </div> */}
            </div>
          </fieldset>

          {/* User logo */}
          {/* {user?.role === 'admin' && 
            <div className="col-span-full mt-10">
              <label htmlFor="photo" className="block text-sm font-medium leading-6 text-gray-900">
                Logo
              </label>
              <div className="mt-2 flex items-center gap-x-3">
                {user.logo && <img
                  className='h-14 w-auto'
                  src={BASE_URL + `/clientLogos/${user.logo}`}
                  alt='Client logo'
                />}
                {!user.logo && 
                 <UserCircleIcon className="h-12 w-12 text-gray-300" aria-hidden="true" />
                }
               
                <button
                  type="button"
                  onClick={() => {
                    setShowUploadFile(true);
                  }}
                  className="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                >
                  Change
                </button>
                
              </div>
              {logoFile?.name}
              {showUploadFile && <UploadFile onChange={handleOnChange}  />}
            </div>
          } */}
          {/* onBlur={() => setShowUploadFile(false)} */}

          <div className='mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6'>
            
            {watch('company') === 'notPrivate' && (
              <div className='col-span-full sm:col-span-4'>
                <label
                  htmlFor='company-name'
                  className='block text-sm font-medium leading-6 text-gray-900'
                >
                  Company name
                </label>
                <div className='mt-2'>
                  <input
                    type='text'
                    // name='company-name'
                    id='company-name'
                    autoComplete='company'
                    {...register('companyName', {
                      required: watch('company')  ? 'Company name is required' : false
                    })}
                    className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-orange-600 hover:ring-orange-500 sm:text-sm sm:leading-6'
                  />
                </div>
                <ErrorMessage
                  errors={errors}
                  name='companyName'
                  render={({ message }) => <p className='text-red-700 font-medium text-xs inline-flex items-center'>{message}</p>}
                />
              </div>
            )}

            <div className='col-span-full sm:col-span-3 '>
              <label
                htmlFor='first-name'
                className='block text-sm font-medium leading-6 text-gray-900'
              >
                First name
              </label>
              <div className='mt-2'>
                <input
                  type='text'
                  id='first-name'
                  {...register('firstName', {
                    required: 'First name is required'
                  })}
                  autoComplete='given-name'
                  className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-orange-600 hover:ring-orange-500 sm:text-sm sm:leading-6'
                />
              </div>
              <ErrorMessage
                errors={errors}
                name='firstName'
                render={({ message }) => <p className='text-red-700 font-medium text-xs inline-flex items-center'>{message}</p>}
              />
            </div>

            <div className='col-span-full sm:col-span-3'>
              <label
                htmlFor='last-name'
                className='block text-sm font-medium leading-6 text-gray-900'
              >
                Last name
              </label>
              <div className='mt-2'>
                <input
                  type='text'
                  id='last-name'
                  {...register('lastName', {
                    required: 'Last name is required'
                  })}
                  autoComplete='family-name'
                  className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-orange-600 hover:ring-orange-500 sm:text-sm sm:leading-6'
                />
              </div>
              <ErrorMessage
                errors={errors}
                name='lastName'
                render={({ message }) => <p className='text-red-700 font-medium text-xs inline-flex items-center'>{message}</p>}
              />
            </div>

            <div className='col-span-full sm:col-span-3'>
              <label
                htmlFor='countryName'
                className='block text-sm font-medium leading-6 text-gray-900'
              >
                Country
              </label>
              <div className='mt-2'>
                <select
                  id='countryName'
                  {...register('countryName', {
                    required: 'Country is required'
                  })}
                  className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-orange-600 hover:ring-orange-500 sm:max-w-xs sm:text-sm sm:leading-6'
                >
                  <option disabled>Choose country</option>
                  {countries && countries.filter(co => co.isEU).map((country) => (
                    <option key={country.id} >{country.countryName}</option>
                  ))}
                </select>
              </div>
              <ErrorMessage
                errors={errors}
                name='countryName'
                render={({ message }) => <p className='text-red-700 font-medium text-xs inline-flex items-center'>{message}</p>}
              />
            </div>

            <div className='col-span-full sm:col-span-4'>
              <label
                htmlFor='street-address'
                className='block text-sm font-medium leading-6 text-gray-900'
              >
                Street address
              </label>
              <div className='mt-2'>
                <input
                  type='text'
                  id='street-address'
                  autoComplete='street-address'
                  {...register('street', {
                    required: 'Street is required'
                  })}
                  className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-orange-600 hover:ring-orange-500 sm:text-sm sm:leading-6'
                />
              </div>
              <ErrorMessage
                errors={errors}
                name='street'
                render={({ message }) => <p className='text-red-700 font-medium text-xs inline-flex items-center'>{message}</p>}
              />
            </div>

            <div className='col-span-1'>
              <label
                htmlFor='address-building'
                className='block text-sm font-medium leading-6 text-gray-900'
              >
                Bldg no.
              </label>
              <div className='mt-2'>
                <input
                  type='text'
                  id='address-building'
                  {...register('building', {
                    required: 'Building number is required'
                  })}
                  className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-orange-600 hover:ring-orange-500 sm:text-sm sm:leading-6'
                />
              </div>
              <ErrorMessage
                errors={errors}
                name='building'
                render={({ message }) => <p className='text-red-700 font-medium text-xs inline-flex items-center'>{message}</p>}
              />
            </div>
            <div className='col-span-1'>
              <label
                htmlFor='address-apt'
                className='block text-sm font-medium leading-6 text-gray-900'
              >
                Apt
              </label>
              <div className='mt-2'>
                <input
                  type='text'
                  id='address-apt'
                  autoComplete='address-apt'
                  {...register('apt', )}
                  className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-orange-600 hover:ring-orange-500 sm:text-sm sm:leading-6'
                />
              </div>
            </div>
            <div className='col-span-full sm:col-span-2 sm:col-start-1'>
              <label
                htmlFor='city'
                className='block text-sm font-medium leading-6 text-gray-900'
              >
                City
              </label>
              <div className='mt-2'>
                <input
                  type='text'
                  id='city'
                  {...register('city', {
                    required: 'City is required'
                  })}
                  autoComplete='address-level2'
                  className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-orange-600 hover:ring-orange-500 sm:text-sm sm:leading-6'
                />
              </div>
              <ErrorMessage
                errors={errors}
                name='city'
                render={({ message }) => <p className='text-red-700 font-medium text-xs inline-flex items-center'>{message}</p>}
              />
            </div>

            {/* <div className='sm:col-span-2'>
              <label
                htmlFor='region'
                className='block text-sm font-medium leading-6 text-gray-900'
              >
                State / Province
              </label>
              <div className='mt-2'>
                <input
                  type='text'
                  name='region'
                  id='region'
                  autoComplete='address-level1'
                  className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-orange-600 hover:ring-orange-500 sm:text-sm sm:leading-6'
                />
              </div>
            </div> */}

            <div className='sm:col-span-2'>
              <label
                htmlFor='postal-code'
                className='block text-sm font-medium leading-6 text-gray-900'
              >
                ZIP / Postal code
              </label>
              <div className='mt-2'>
                <input
                  type='text'
                  id='postal-code'
                  autoComplete='postal-code'
                  {...register('postCode', {
                    required: 'Post code is required'
                  })}
                  className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-orange-600 hover:ring-orange-500 sm:text-sm sm:leading-6'
                />
              </div>
              <ErrorMessage
                errors={errors}
                name='postCode'
                render={({ message }) => <p className='text-red-700 font-medium text-xs inline-flex items-center'>{message}</p>}
              />
            </div>
            {watch('company') === 'notPrivate' && (
              <div className='sm:col-span-3'>
                <label
                  htmlFor='company-vat'
                  className='block text-sm font-medium leading-6 text-gray-900'
                >
                VAT or Company registration no.
                </label>
                <div className='mt-2'>
                  <input
                    type='text'
                    id='company-vat'
                    {...register('VAT_EU', {
                      required: 'VAT number or company registration number is required'
                    })}
                    className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-orange-600 hover:ring-orange-500 sm:text-sm sm:leading-6'
                  />
                </div>
                <ErrorMessage
                  errors={errors}
                  name='VAT_EU'
                  render={({ message }) => <p className='text-red-700 font-medium text-xs inline-flex items-center'>{message}</p>}
                />
              </div>
            )}
            <div className='sm:col-span-full'>
              <label
                htmlFor='company-vat'
                className='block text-sm font-medium leading-6 text-gray-900'
              >
                Payment terms
              </label>
              <div className='mt-2'>

                {user?.role !== 'admin' && user?.postPaid
                  ? (<div>7 days</div>)
                  : (<div>Pre-paid</div>)}

                {/* <input
                  type='text'
                  name='company-vat'
                  id='company-vat'
                  value={user.VAT_EU}
                  onChange={(e) => {
                    setUser({
                      ...user,
                      VAT_EU: e.target.value,
                    });
                  }}
                  className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-orange-600 hover:ring-orange-500 sm:text-sm sm:leading-6'
                /> */}
              </div>
            </div>

          </div>
        </div>

        {/* <div className='border-b border-gray-900/10 pb-12'>
          <h2 className='text-base font-semibold leading-7 text-gray-900'>
            Notifications
          </h2>
          <p className='mt-1 text-sm leading-6 text-gray-600'>
            We&apos;ll always let you know about important changes, but you pick
            what else you want to hear about.
          </p>

          <div className='mt-10 space-y-10'>
            <fieldset>
              <legend className='text-sm font-semibold leading-6 text-gray-900'>
                By Email
              </legend>
              <div className='mt-6 space-y-6'>
                <div className='relative flex gap-x-3'>
                  <div className='flex h-6 items-center'>
                    <input
                      id='comments'
                      name='comments'
                      type='checkbox'
                      className='h-4 w-4 rounded border-gray-300 text-orange-600 focus:ring-orange-600 hover:ring-orange-500'
                    />
                  </div>
                  <div className='text-sm leading-6'>
                    <label
                      htmlFor='comments'
                      className='font-medium text-gray-900'
                    >
                      Comments
                    </label>
                    <p className='text-gray-500'>
                      Get notified when someones posts a comment on a posting.
                    </p>
                  </div>
                </div>
                <div className='relative flex gap-x-3'>
                  <div className='flex h-6 items-center'>
                    <input
                      id='candidates'
                      name='candidates'
                      type='checkbox'
                      className='h-4 w-4 rounded border-gray-300 text-orange-600 focus:ring-orange-600 hover:ring-orange-500'
                    />
                  </div>
                  <div className='text-sm leading-6'>
                    <label
                      htmlFor='candidates'
                      className='font-medium text-gray-900'
                    >
                      Candidates
                    </label>
                    <p className='text-gray-500'>
                      Get notified when a candidate applies for a job.
                    </p>
                  </div>
                </div>
                <div className='relative flex gap-x-3'>
                  <div className='flex h-6 items-center'>
                    <input
                      id='offers'
                      name='offers'
                      type='checkbox'
                      className='h-4 w-4 rounded border-gray-300 text-orange-600 focus:ring-orange-600 hover:ring-orange-500'
                    />
                  </div>
                  <div className='text-sm leading-6'>
                    <label
                      htmlFor='offers'
                      className='font-medium text-gray-900'
                    >
                      Offers
                    </label>
                    <p className='text-gray-500'>
                      Get notified when a candidate accepts or rejects an offer.
                    </p>
                  </div>
                </div>
              </div>
            </fieldset>
          </div>
        </div> */}
      </div>

      <div className='mt-6 flex items-center justify-end gap-x-6'>
        <button
          type='button'
          className='text-sm font-semibold leading-6 text-gray-900'
          onClick={() => navigate('/')}
        >
          Cancel
        </button>
        <button
          type='submit'
          className='rounded-md bg-orange-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600'
        >
          Save
        </button>
      </div>
    </form>
  );
};
