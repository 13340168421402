import { useNavigate, useSearchParams } from 'react-router-dom';
import { tableHeader } from '../../data/trucksTable';
import { FunnelIcon } from '@heroicons/react/24/outline';
import { Pagination } from '../Pagination/Pagination';
import { SortBy } from '../Search/SortBy';
import { useTrucksContext } from '../../context/TrucksProvider';
import cn from 'classnames';
import { useEffect } from 'react';

export const TrucksList = () => {
  const { trucks, isLoading, trucksCount, setQuery } = useTrucksContext();
  const [ searchParams, setSearchParams ] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    const params =  searchParams.toString();

    if (params.length > 0) {
      setQuery(params);
    } else {
      setQuery('');
    }
   
  }, [ searchParams ]);



  const handleChooseTruck = async ( truckNo: string | undefined ) => {

    if (!truckNo) {
      return;
    }

    navigate(`/vans/${truckNo}`);

  };

  if (!trucks) {
    return null;
  }
  
  return (
    <div className='px-4 sm:px-6 lg:px-8'>
      <div className='sm:flex sm:items-center'>
        <div className='sm:flex-auto'>
          <h1 className='text-base font-semibold leading-6 text-gray-900'>
            Trucks
          </h1>
          <p className='mt-2 text-sm text-gray-700'>
            A list of all your trucks.
          </p>
        </div>
        <form className='relative flex flex-1' action='#' method='GET'>
          <label htmlFor='filter-field'
            className='sr-only'
            // className="block text-sm font-medium leading-6 text-gray-900"
          >
                  Filter
          </label>
          <FunnelIcon
            className='pointer-events-none absolute inset-y-0 left-3 h-full w-5 text-gray-400'
            aria-hidden='true'
          />
          <input
            id='filter-field'
            // className='block h-full w-full btruck-0 py-0 pl-8 pr-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm'
            className="block w-full px-10 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset hover:ring-orange-500 focus:ring-orange-600 sm:text-sm sm:leading-6"
            // className="block w-full px-10 rounded-md btruck-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset hover:ring-orange-500 focus:ring-orange-600 sm:text-sm sm:leading-6"
            placeholder='Filter...'
            type='text'
            name='filter'
            value={searchParams.get('query') || ''}
            onChange={(e) => {
              searchParams.set('query', `${e.target.value}`);
  
              if (searchParams.get('query') === '') {
                searchParams.delete('query');
              }
  
              setSearchParams(searchParams);
            }}
          />
         
        </form>

        {/* <div className='mt-4 sm:ml-16 sm:mt-0 sm:flex-none'>
            
          <button
            type='button'
            onClick={() => navigate('/new-truck')}
            className='block rounded-md bg-orange-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600'
          >
            Add truck
          </button>
        </div> */}
      </div>
      <div className='mt-8 flow-root'>
        <div className='-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8'>
          <div className='inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8'>
            {!isLoading && <table className='min-w-full divide-y divide-gray-300'>
              <thead>
                <tr>
                  {Object.entries(tableHeader).map(([ column, sort ], index) => (
                    <th
                      key={column}
                      scope='col'
                      className={cn('pr-3 py-3.5 text-left text-sm font-semibold text-gray-900', {
                        'px-3': index !== 0
                      })}
                    >
                      <SortBy  sort={sort} label={column} />
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className='divide-y divide-gray-200 bg-white'>
                {trucks && trucks.filter(activeTruck => ((new Date()).getDay() - (new Date(activeTruck.pos_time as string)).getDay()) < 50).map((truck) => (
                  <tr key={truck.objectno} className='hover:bg-orange-600/20 cursor-pointer' onClick={() => handleChooseTruck(truck.objectno)}>
                    <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm  font-medium text-gray-900 sm:pl-0'>
                      {truck.objectname}
                    </td>
                    <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                      {truck.odometer?.toLocaleString('de-DE') + 'km'}
                    </td>
                    <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                      {truck.fuellevel? +truck.fuellevel / 10 + '%' : 0}
                    </td>
                    <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                      {+truck.floorArea / 10000 + 'm\xB2'}
                    </td>
                    <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                      {truck.actualLoad + 'kg'}
                    </td>
                    <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                      {truck.obu_serialno}
                    </td>
                    <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                      {truck.active ?
                        <span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                         Active
                        </span>
                        :  <span className="inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10">
                        Not Active
                        </span>}
                    </td>
                    {/* <td className='relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm sm:pr-0'>
                      <a
                        href='#'
                        className='text-orange-600 hover:text-orange-900'
                      >
                        Edit<span className='sr-only'></span>
                      </a>
                    </td> */}
                  </tr>
                ))}
              </tbody>
              
            </table>}
            
          </div>
          
        </div>
      </div>
      {Math.ceil(trucksCount / Number(searchParams.get('perPage'))) > 1 && <Pagination totalItems={trucksCount} />}  
     
    </div>
  );
};
