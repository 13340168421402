import { useIsFetching, useIsMutating } from '@tanstack/react-query';
import { useLocation } from 'react-router-dom';
 
export const Loader = () => {
 
  const locate = useLocation();
  const site = locate.pathname.split('/')[1];
  const params = locate.search.split('?')[1];
 
  const isFetching = useIsFetching({
    queryKey: [ site, params ]
  });

  const isMutating = useIsMutating();
 
  if (!isFetching && !isMutating) {
    return null;
  }

  return (
    <div className="fixed inset-0 bg-gray-100 bg-opacity-75 transition-opacity" >
      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full  justify-center  text-center items-center p-0">
          <div
            className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite] text-orange-600"
            role="status">
            <span
              className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
            >Loading...</span>
          </div>
        </div>
      </div>
    </div >
 
  );
};