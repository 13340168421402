import { apiClient } from '../services/axiosClient';

export const getVans = async (params: string) => {

  params = params ? '?' + params : '';
  
  try {
    const data =  await apiClient.get('/vans' + params);

    return data;

  } catch (error) {
    console.log('Problem downloading vans');
  }
 
};

export const getVan = async (truckId: string) => {
  try {
    const data = await apiClient.get(`/vans/${truckId}`);
    return data;
  } catch (e) {
    console.log('Problem downloading van: ', e);
  }
};
