import { useEffect, useState } from 'react';
import { TomTomResult } from '../../../types/TomTomData';
import { AutoFill } from '../../AutoFillForm/AutoFill';
import {  useFormContext } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { useGetCountries } from '../../../hooks/useCountries';
import { Loader } from '../../Loader/Loader';


export const ReceiverContact =() => {
  const { register, watch, setValue, formState: { errors } } = useFormContext();
  const [ receiverAddress, setReceiverAddress ] = useState<TomTomResult | null>(null);
  const { countries, isLoading } = useGetCountries();

  const countryCode = countries?.find(co => co.countryName === watch('receiver.country'))?.countryCode;

  useEffect(() => {
    if (receiverAddress !== null) {
      setValue('receiver.street', receiverAddress.address?.streetName || '');
      setValue('receiver.buildingNo', receiverAddress.address?.streetNumber || '');
      setValue('receiver.city', receiverAddress.address?.municipality || '');
      setValue('receiver.postCode', receiverAddress.address?.postalCode || '');
      setValue('receiver.country', receiverAddress.address?.country || '');
      setValue('receiver.state', receiverAddress.address?.countrySubdivision || '');
      setValue('receiver.countryCode', receiverAddress.address?.countryCode || '');
      setValue('receiver.coordinates.lat', receiverAddress.position?.lat || '');
      setValue('receiver.coordinates.lon', receiverAddress.position?.lon || '');
    } else {
      setValue('receiver.street', '');
      setValue('receiver.buildingNo', '');
      setValue('receiver.city', '');
      setValue('receiver.postCode', '');
      setValue('receiver.state', '');
      setValue('receiver.countryCode', '');
      setValue('receiver.coordinates.lat', '');
      setValue('receiver.coordinates.lon', '');
    }
  }, [ receiverAddress, setValue ]);
  
  if (isLoading) {
    return <Loader/>;
  }
  
  return (
    <div className='grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-5'>
      <div>
        <h2 className='text-base font-semibold leading-7 text-gray-900'>
        Receiver
        </h2>

        <p className='mt-1 text-sm leading-6 text-gray-600'>
        Name and address of the receiver.
        </p>
      </div>

      <div className='grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-4'>
   
        <div className='sm:col-span-3'>
          <label
            htmlFor='receiver-first-name'
            className='block text-sm font-medium leading-6 text-gray-900'
          >
          First name
          </label>

          <div className='mt-2'>
            <input
              type='text'
              id='receiver-first-name'
              autoComplete='given-name'
              {...register('receiver.name', { required: 'Name is required' })}
              className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset  hover:ring-orange-500 focus:ring-orange-600 sm:text-sm sm:leading-6'
            />
            <ErrorMessage
              errors={errors}
              name='receiver.name'
              render={({ message }) => <p className='text-red-700 font-medium text-xs inline-flex items-center'>{message}</p>}
            />
          </div>
        </div>

        <div className='sm:col-span-3'>
          <label
            htmlFor='receiver-last-name'
            className='block text-sm font-medium leading-6 text-gray-900'
          >
          Last name
          </label>

          <div className='mt-2'>
            <input
              type='text'
              id='receiver-last-name'
              autoComplete='family-name'
              {...register('receiver.surname', { required: 'Surname is required' })}
              className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset hover:ring-orange-500 focus:ring-orange-600 sm:text-sm sm:leading-6'
            />
            <ErrorMessage
              errors={errors}
              name='receiver.surname'
              render={({ message }) => <p className='text-red-700 font-medium text-xs inline-flex items-center'>{message}</p>}
            />
          </div>
        </div>

   
        <div className='sm:col-span-4'>
          <label
            htmlFor='receiver-company'
            className='block text-sm font-medium leading-6 text-gray-900'
          >
          Company
          </label>

          <div className='mt-2'>
            <input
              id='receiver-company'
              type='text'
              autoComplete='organization'
              {...register('receiver.company')}
              className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset hover:ring-orange-500 focus:ring-orange-600 sm:text-sm sm:leading-6'
            />
          </div>
        </div>

        <div className='sm:col-span-3'>
          <label
            htmlFor='receiver-email'
            className='block text-sm font-medium leading-6 text-gray-900'
          >
          Email address
          </label>
          <div className='mt-2'>
            <input
              id='receiver-email'
              type='email'
              autoComplete='email'
              {...register('receiver.email', {
                required: 'E-mail is required',
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: 'Invalid email address'
                }  
              })}
              className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset hover:ring-orange-500 focus:ring-orange-600 sm:text-sm sm:leading-6'
            />
            <ErrorMessage
              errors={errors}
              name='receiver.email'
              render={({ message }) => <p className='text-red-700 font-medium text-xs inline-flex items-center'>{message}</p>}
            />
          </div>
        </div>

        <div className='sm:col-span-3'>
          <label
            htmlFor='receiver-phone'
            className='block text-sm font-medium leading-6 text-gray-900'
          >
          Phone no.
          </label>

          <div className='mt-2'>
            <input
              id='receiver-phone'
              type='tel'
              autoComplete='tel'
              {...register('receiver.phone', { required: 'Phone no. is required' })}
              className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset hover:ring-orange-500 focus:ring-orange-600 sm:text-sm sm:leading-6'
            />
            <ErrorMessage
              errors={errors}
              name='receiver.phone'
              render={({ message }) => <p className='text-red-700 font-medium text-xs inline-flex items-center'>{message}</p>}
            />
          </div>
        </div>

        <div className='sm:col-span-3'>
          <label
            htmlFor='receiver-country'
            className='block text-sm font-medium leading-6 text-gray-900'
          >
          Country
          </label>

          <div className='mt-2'>
            <select
              id='receiver-country'
              defaultValue={''}
              autoComplete='country-name'
              {...register('receiver.country', { required: 'Country is required' })}
              className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset hover:ring-orange-500 focus:ring-orange-600 sm:max-w-xs sm:text-sm sm:leading-6'
            >
              <option value={''} disabled >-- Choose country --</option>
              {countries && countries.filter(count => count.isEnabled).filter(value => watch('sender.country') !== 'Poland' ? watch('sender.country') !== value.countryName : value.countryName).map(country => (
                <option key={country.id}>{country.countryName}</option>
              ))}
            </select>
            <ErrorMessage
              errors={errors}
              name='receiver.country'
              render={({ message }) => <p className='text-red-700 font-medium text-xs inline-flex items-center'>{message}</p>}
            />
          </div>
        </div>
        {watch('receiver.country') && 
<>

  <div className='sm:col-span-full'>
    <AutoFill setChosenData={setReceiverAddress} country={countryCode} />
  </div>

  <div className='sm:col-span-4'>
    <label
      htmlFor='receiver-street-address'
      className='block text-sm font-medium leading-6 text-gray-900'
    >
          Street address
    </label>

    <div className='mt-2'>
      <input
        type='text'
        id='receiver-street-address'
        autoComplete='address-line1'
        
        disabled
        {...register('receiver.street', { required: 'Street is required' })}
        className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset hover:ring-orange-500 focus:ring-orange-600 sm:text-sm sm:leading-6 cursor-not-allowed'
      />
      <ErrorMessage
        errors={errors}
        name='receiver.street'
        render={({ message }) => <p className='text-red-700 font-medium text-xs inline-flex items-center'>{message}</p>}
      />
    </div>
  </div>

  <div className='sm:col-span-1'>
    <label
      htmlFor='receiver-building-address'
      className='block text-sm font-medium leading-6 text-gray-900'
    >
          Bldg. No.
    </label>

    <div className='mt-2'>
      <input
        type='text'
        id='receiver-building-address'
        autoComplete='address-line2'
        disabled
        {...register('receiver.buildingNo', { required: 'Building no is required' })}
        className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset hover:ring-orange-500 focus:ring-orange-600 sm:text-sm sm:leading-6 cursor-not-allowed'
      />
      <ErrorMessage
        errors={errors}
        name='receiver.buildingNo'
        render={({ message }) => <p className='text-red-700 font-medium text-xs inline-flex items-center'>{message}</p>}
      />
    </div>
  </div>

  <div className='sm:col-span-1'>
    <label
      htmlFor='receiver-apt-address'
      className='block text-sm font-medium leading-6 text-gray-900'
    >
          Apt
    </label>

    <div className='mt-2'>
      <input
        type='text'
        id='receiver-apt-address'
        autoComplete='address-line3'
        {...register('receiver.apartmentNo')}
        className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset hover:ring-orange-500 focus:ring-orange-600 sm:text-sm sm:leading-6'
      />
    </div>
  </div>

  <div className='sm:col-span-2 sm:col-start-1'>
    <label
      htmlFor='receiver-city'
      className='block text-sm font-medium leading-6 text-gray-900'
    >
          City
    </label>

    <div className='mt-2'>
      <input
        type='text'
        id='receiver-city'
        autoComplete='address-level2'
        disabled
        {...register('receiver.city', { required: 'City is required' })}
        className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset hover:ring-orange-500 focus:ring-orange-600 sm:text-sm sm:leading-6 cursor-not-allowed'
      />
      <ErrorMessage
        errors={errors}
        name='receiver.city'
        render={({ message }) => <p className='text-red-700 font-medium text-xs inline-flex items-center'>{message}</p>}
      />
    </div>
  </div>

  <div className='sm:col-span-2'>
    <label
      htmlFor='receiver-region'
      className='block text-sm font-medium leading-6 text-gray-900'
    >
          State / Province
    </label>

    <div className='mt-2'>
      <input
        type='text'
        id='receiver-region'
        autoComplete='address-level1'
        disabled
        {...register('receiver.state')}
        className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset hover:ring-orange-500 focus:ring-orange-600 sm:text-sm sm:leading-6 cursor-not-allowed'
      />
    </div>
  </div>

  <div className='sm:col-span-2'>
    <label
      htmlFor='receiver-postal-code'
      className='block text-sm font-medium leading-6 text-gray-900'
    >
          ZIP / Postal code
    </label>

    <div className='mt-2'>
      <input
        type='text'
        id='receiver-postal-code'
        autoComplete='postal-code'
        disabled
        {...register('receiver.postCode', { required: 'Post Code is required' })}
        className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset hover:ring-orange-500 focus:ring-orange-600 sm:text-sm sm:leading-6 cursor-not-allowed'
      />
      <ErrorMessage
        errors={errors}
        name='receiver.postCode'
        render={({ message }) => <p className='text-red-700 font-medium text-xs inline-flex items-center'>{message}</p>}
      />
    </div>
  </div>

  <div className='col-span-full'>
    <label
      htmlFor='receiver-notes'
      className='block text-sm font-medium leading-6 text-gray-900'
    >
          Notes
    </label>

    <div className='mt-2'>
      <input
        type='text'
        placeholder='ex.: you have to use the back door'
        id='receiver-notes'
        {...register('receiver.notes')}
        className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset hover:ring-orange-500 focus:ring-orange-600 sm:text-sm sm:leading-6'
      />
    </div>
  </div>
</>}
          
      </div>
    </div>
  );
};