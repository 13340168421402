import { TruckIco } from '../Map/TruckIco';

export const Invoices = () => {
  return (
    <>
      <div>Invoices</div>;
      <TruckIco color='#D85930'/>
    </>);
  
};
