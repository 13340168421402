import { useEffect, useState } from 'react';
import { tomtomClient } from '../../services/axiosClient';
import { TomTomData, TomTomResult } from '../../types/TomTomData';
import cn from 'classnames';

type AutoFillProps = {
 setChosenData: React.Dispatch<React.SetStateAction<TomTomResult | null>>
 country: string | undefined
} 

export const AutoFill = ({ setChosenData, country }: AutoFillProps) => {
  const [ text, setText ] = useState('');
  const [ debouncedText, setDebouncedText ] = useState(text);
  const [ tomTomData, setTomTomData ] = useState<TomTomData | null>(null);
  const [ searchBoxFocus, setSearchBoxFocus ] = useState<boolean>(false);
  const [ activeIndex, setActiveIndex ] = useState(0);
  // const { countries } = useGetCountries();
  const handleSetAddress = (id: string) => {
    if (tomTomData) {
      const result = tomTomData.results.find(result => result.id === id) as TomTomResult;
      setChosenData(result);
    }

    setText('');
 
  };

  const handleBlur = () => {
    setTimeout(() => {
      setSearchBoxFocus(false);
    }, 300);
    
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (!tomTomData) {
      return;
    }
  
    const listLength = tomTomData.results.length;
    if (!listLength) {
      return;
    }

    if (e.key === 'ArrowDown') {
      e.preventDefault(); // Prevent the default action (scrolling) on arrow down
      setActiveIndex(prevIndex => (prevIndex < listLength - 1 ? prevIndex + 1 : prevIndex));
    } else if (e.key === 'ArrowUp') {
      e.preventDefault(); // Prevent the default action (scrolling) on arrow up
      setActiveIndex(prevIndex => (prevIndex > 0 ? prevIndex - 1 : 0));
    } else if (e.key === 'Enter') {
      e.preventDefault();
      const activeItem = tomTomData.results[activeIndex];
      if (activeItem) {
        handleSetAddress(activeItem.id);
        // setSearchBoxFocus(false); // Optionally close the list after selection
        setActiveIndex(0);
      }
    }
  };

  const handleFocus = () => {
    setSearchBoxFocus(true);
  };

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedText(text);
    }, 300); // 300ms delay

    return () => {
      clearTimeout(handler);
    };
  }, [ text ]);

  useEffect(() => {

    const getTomTomData = async () => {
      const URLencodedQuery = debouncedText.split(' ').join('%20');

      try {
        const tomData = await tomtomClient.get(`https://api.tomtom.com/search/2/search/${URLencodedQuery}.json?typeahead=true&limit=6&fuelSet=&view=Unified&relatedPois=off&minFuzzyLevel=1&idxSet=PAD,Addr,Geo,Str&maxFuzzyLevel=2&countrySet=${country}&key=PJaHZ0PirSq2vVtCDUlaGiWftyAKp9KL&language=en-US`);

        setTomTomData(tomData.data);
      } catch (e) {
        console.error(e);
      }
    };

    if (debouncedText) {
      getTomTomData();
    }
  }, [ debouncedText ]);
 
  // 
  return (
    <div className='sm:col-span-4'>
      <label
        htmlFor='receiver-company'
        className='block text-sm font-medium leading-6 text-gray-900'
      >
      Address autocomplete
      </label>

      <div  className='mt-2 relative'>
        <input
          onBlur={handleBlur}
          onFocus={handleFocus}
          id='receiver-company'
          name='receiver-company'
          type='text'
          value={text}
          onKeyDown={e => handleKeyDown(e)}
          placeholder='Type your address here'
          autoComplete='organization'
          onChange={(e) => setText(e.target.value)}
          className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset hover:ring-orange-500 focus:ring-orange-600 sm:text-sm sm:leading-6'
        />
        {searchBoxFocus && text.length > 1 && tomTomData && tomTomData.results && 
            <ul  className='absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm cursor-pointer'>
              {tomTomData.results.map((result, index) => {
                return (
                  <li onClick={() => handleSetAddress(result.id)} key={result.id} className={cn('pl-3 hover:bg-orange-600/50', {
                    'bg-orange-600/50': index === activeIndex
                  })}>{result.address.freeformAddress}</li>
                );
              }) }
            </ul>
        }
    
      </div>
    </div>
  );
};
