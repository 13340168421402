
export const queryKeys = {
  orders: 'orders',
  order: 'order',
  userId: 'userId',
  users: 'users',
  user: 'user',
  price: 'price',
  countries: 'countries',
  userContacts: 'userContacts'
};