import { client } from '../services/axiosClient';


export const verifyToken = async (token: string) => {
  let activationStatus;
  try {
    const response = await client.get(`/activate/${token}`);
    // console.log(response);
    if (response.status === 200) {
      activationStatus = 'Account Activated'; 
    } else {
      activationStatus = 'Failed to activate account. Please try again.';
    }
  } catch (error) {
 
    activationStatus = 'General error occurred. Please try again later.';
    
  }
  return activationStatus;
};
