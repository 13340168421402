
import { useAuthContext } from '../context/AuthProvider';

import { usersService } from '../api/users';
import { useMutation, useQuery } from '@tanstack/react-query';
import { queryKeys } from '../react-query/query-keys';
import { useParams } from 'react-router-dom';
import { User } from '../types/User';
import { queryClient } from '../react-query/queryClient';
import { toast } from 'react-toastify';
import { useEffect, useState } from 'react';

// export const emptyUser = {
//   id: undefined,
//   firstName: '',
//   lastName: '',
//   email: '',
//   password: '',
//   role: '',
//   logo: '',
//   companyName: '',
//   country: '',
//   city: '',
//   street: '',
//   building: '',
//   apt: '',
//   postCode: '',
//   proffesional: false,
//   company: false,
//   eori: '',
//   postPaid: false,
//   VAT_EU:  '',
// };

export const useFetchUser = () => {
  const { activeUser } = useAuthContext();
  const [ isUserData, setIsUserData ] = useState(true);

  const { data: user } = useQuery({
    enabled: !!activeUser,
    queryKey: [ activeUser?.id ],
    queryFn: () => usersService.getUser((activeUser?.id as number).toString())
  });

  useEffect(() => {
    if (user && user?.country && user?.street && user?.building && user?.postCode && user?.email && user?.lastName && user?.firstName) {
      setIsUserData(true);
    } else if (user) {
      setIsUserData(false);
    }
  }, [ user ]);

  return { user, isUserData };
};

export const useUpdateUser = () => {
  const { activeUser } = useAuthContext();
  const { mutate: updateUser } = useMutation({
    mutationFn: (user: Partial<User>) => usersService.updateUser(user),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [ activeUser?.id ] });
      toast.success('User updated', {
        autoClose: 2000
      });
    }
  });

  return {
    updateUser
  };
};

export const useFetchUsers = (queryParams: string) => {
  const params = useParams();

  const { data: users } = useQuery({
    queryKey: [ queryKeys.users, params ],
    queryFn: () => usersService.getUsers(queryParams)
  });

  return {
    users
  };
};
const fallbackData = {
  rows: [],
  count: 0
};

export const useFetchAllPayers = () => {
  const { data = fallbackData } = useQuery({
    queryKey: [],
    queryFn: () => usersService.getAllPayers()
  });

  const clients = data.rows;
  const count = data.count;
  return {
    clients,
    count
  };
};