import { createContext, useContext } from 'react';
import { useTrucks } from '../hooks/useTrucks';

type TrucksContextType = ReturnType<typeof useTrucks> | undefined

export const TrucksContext = createContext<TrucksContextType>(undefined);

type TrucksProviderProps = {
  children: React.ReactNode
}
export const TrucksProvider = ({ children }: TrucksProviderProps) => {
  
  const { ...args } = useTrucks();

  return (
    <TrucksContext.Provider value={{ ...args }}>
      {children}
    </TrucksContext.Provider>
  );

};

export const useTrucksContext = () => {
  const contextValues = useContext(TrucksContext);

  if (!contextValues) {
    throw Error ('Trucks context must be within TrucsProvider!');
  }

  return contextValues;
};