import { useMutation, useQuery } from '@tanstack/react-query';
import { useAuthContext } from '../context/AuthProvider';
import { queryKeys } from '../react-query/query-keys';
import { OrderResponseData, ordersService } from '../api/orders';
import { Order } from '../types/Order';
import { toast } from 'react-toastify';
import { queryClient } from '../react-query/queryClient';

export const useFetchOrders = (params: string) => {
  const { activeUser } = useAuthContext();
  // const queryClient = useQueryClient();
  console.log('params: ', params);
  const fallbackData: OrderResponseData = {
    rows: [],
    count: 0
  };

  const { data: ordersData = fallbackData, isLoading } = useQuery({
    enabled: !!activeUser,
    queryKey: [ queryKeys.orders, params ],
    queryFn: () => ordersService.getOrders(params)
  });


  return {
    ordersData,
    isLoading
  };
};

export const useFetchOrder = (orderId: number) => {

  const { data: order, isLoading } = useQuery({
    enabled: !!orderId,
    queryKey: [ queryKeys.order, orderId ],
    queryFn: () => ordersService.getOrder(orderId)
  });

  return {
    order,
    isLoading
  };
};

export const useAddOrder = () => {
  
  const { mutate: addOrder } = useMutation({
    mutationFn: (order: Partial<Order>) => ordersService.createOrder(order),
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: [ queryKeys.orders ] });
      toast.success('Order created!');
      return data;
    }
  });

  return { addOrder };
};

export const useDeleteOrder = () => {

  const { mutate: deleteOrder } = useMutation({
    mutationFn: (orderId: number) => ordersService.deleteOrder(orderId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [ queryKeys.orders, queryKeys.order ] });
      toast.success('Order deleted');
    }
  });

  return {
    deleteOrder
  };
};