import cn from 'classnames';
import { FlagIcon, MapIcon } from '@heroicons/react/24/outline';
import { NavLink, Outlet,  } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

const tabs = [
  { name: 'Countries', href: 'countries', icon: FlagIcon, current: true },
  { name: 'Zones', href: 'zones', icon: MapIcon, current: false },
  { name: 'Countries & Zones', href: 'countries-zones', icon: FlagIcon, current: true },

];

export const SettingsPage = () => {
  const location = useLocation();
  const loc = location.pathname.split('/')[2];

  if (!tabs ) {
    return null;
  }

  return (
    <div>
      <div className="block">
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex space-x-8" aria-label="Tabs">
            {tabs.map((tab) => (
              <NavLink
                key={tab.name}
                to={tab.href}
                className={cn('group inline-flex items-center border-b-2 py-4 px-1 text-sm font-medium',{
                  'border-orange-500 text-orange-600': tab.href === loc,
                  'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700': tab.href !== loc
                }
                )}
                aria-current={tab.href === loc ? 'page' : undefined}
              >
                <tab.icon
                  className={cn('-ml-0.5 mr-2 h-5 w-5', {
                    'text-orange-500': tab.href === loc,
                    'text-gray-400 group-hover:text-gray-500': tab.href !== loc
                  }
                  )}
                  aria-hidden="true"
                />
                <span>{tab.name}</span>
              </NavLink>
            ))}
          </nav>
        </div>
      </div>
      <Outlet />
    </div>
  );
};
