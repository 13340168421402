import { useFieldArray, useFormContext } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';




export const PackageList = () => {
  const { register, watch, formState: { errors }, control } = useFormContext(); 
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'packages'
  });

  const removePackage = (index:number) => {
    
    remove(index);
    
  
  };

  const addPackage = () => {
    append({
      id: (fields.length + 1).toString(),
      type: 'other',
      goods: '',
      width: '',
      length: '',
      height: '',
      weight: '',
      pcs: 1,
      link: '',
      ref: '',
      chairs: false
    });
  };
  return (
    <div className='grid max-w-2xl grid-cols-1 gap-x-6 gap-y-2 sm:grid-cols-1 md:col-span-4'>
      {fields.map((pcg, index) => (
        <div key={pcg.id} className='border-b border-gray-900/10 pb-5 grid max-w-2xl grid-cols-1 gap-x-6 gap-y-2 sm:grid-cols-6 md:col-span-4'>
          
          <div className='sm:col-span-4 sm:col-start-1'>
            <div><b>Package no.{index + 1}</b></div>
            <label
              htmlFor={`packages.${index}.goods`}
              className='block text-sm font-medium leading-6 text-gray-900'
            >
                Contents
            </label>
            <div className='mt-2'>
              <input
                type='text'
                id={`packages.${index}.goods`}
                {...register(`packages.${index}.goods` as const, { required: 'Content is required' })}
                className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset hover:ring-orange-500 focus:ring-orange-600 sm:text-sm sm:leading-6'
              />
              <ErrorMessage
                errors={errors}
                name={`packages.${index}.goods`}
                render={({ message }) => <p className='text-red-700 font-medium text-xs inline-flex items-center'>{message}</p>}
              />
            </div>
        
          </div>
    
          <div className='sm:col-span-1 sm:col-start-1'>
            <label
              htmlFor={`packages.${index}.width`}
              className='block text-sm font-medium leading-6 text-gray-900'
            >
                Width
            </label>
            <div className='mt-2'>
              <input
                type='number'
                placeholder='[cm]'
                id={`packages.${index}.width`}
                {...register(`packages.${index}.width` as const, {
                  required: true,
                  max: {
                    value: 200,
                    message: 'Maximum width is 200cm'
                  },
                  min: {
                    value: 1,
                    message: 'Value must be higher than 0'
                  }
                })}
                className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset hover:ring-orange-500 focus:ring-orange-600 sm:text-sm sm:leading-6'
              />
              <ErrorMessage
                errors={errors}
                name={`packages.${index}.width`}
                render={({ message }) => <p className='text-red-700 font-medium text-xs inline-flex items-center'>{message}</p>}
              />
            </div>
          </div>
          <div className='sm:col-span-1'>
            <label
              htmlFor={`packages.${index}.length`}
              className='block text-sm font-medium leading-6 text-gray-900'
            >
                length
            </label>
            <div className='mt-2'>
              <input
                type='number'
                placeholder='[cm]'
                id={`packages.${index}.length`}
                {...register(`packages.${index}.length` as const, {
                  required: true,
                  max: {
                    value: 200,
                    message: 'Maximum length is 200cm'
                  },
                  min: {
                    value: 1,
                    message: 'Value must be higher than 0'
                  }
                })}
                className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset hover:ring-orange-500 focus:ring-orange-600 sm:text-sm sm:leading-6'
              />
              <ErrorMessage
                errors={errors}
                name={`packages.${index}.length`}
                render={({ message }) => <p className='text-red-700 font-medium text-xs inline-flex items-center'>{message}</p>}
              />
            </div>
          </div>
          <div className='sm:col-span-1'>
            <label
              htmlFor={`packages.${index}.height`}
              className='block text-sm font-medium leading-6 text-gray-900'
            >
                Height
            </label>
            <div className='mt-2'>
              <input
                type='number'
                placeholder='[cm]'
                id={`packages.${index}.height`}
                {...register(`packages.${index}.height` as const, {
                  required: true,
                  max: {
                    value: 190,
                    message: 'Maximum height is 190cm'
                  },
                  min: {
                    value: 1,
                    message: 'Value must be higher than 0'
                  }
                })}
                className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset hover:ring-orange-500 focus:ring-orange-600 sm:text-sm sm:leading-6'
              />
              <ErrorMessage
                errors={errors}
                name={`packages.${index}.height`}
                render={({ message }) => <p className='text-red-700 font-medium text-xs inline-flex items-center'>{message}</p>}
              />
            </div>
          </div>
    
          <div className='sm:col-span-1'>
            <label
              htmlFor={`packages.${index}.weight`}
              className='block text-sm font-medium leading-6 text-gray-900'
            >
                Weight
            </label>
            <div className='mt-2'>
              <input
                type='number'
                placeholder='[kg]'
                id={`packages.${index}.weight`}
                {...register(`packages.${index}.weight` as const, {
                  required: true,
                  max: {
                    value: 100,
                    message: 'Maximum weight is 100kg'
                  },
                  min: {
                    value: 1,
                    message: 'Value must be higher than 0'
                  }
                })}
                className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset hover:ring-orange-500 focus:ring-orange-600 sm:text-sm sm:leading-6'
              />
              <ErrorMessage
                errors={errors}
                name={`packages.${index}.weight`}
                render={({ message }) => <p className='text-red-700 font-medium text-xs inline-flex items-center'>{message}</p>}
              />
            </div>
          </div>
    
          <div className='sm:col-span-1 sm:col-end-7'>
            <label
              htmlFor={`packages.${index}.pcs`}
              className='block text-sm font-medium leading-6 text-gray-900'
            >
                Quantity
            </label>
            <div className='mt-2'>
              <input
                type='number'
                id={`packages.${index}.pcs`}
                placeholder='pcs'
                {...register(`packages.${index}.pcs` as const, {
                  required: true,
                  valueAsNumber: true
                })}
                className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset hover:ring-orange-500 focus:ring-orange-600 sm:text-sm sm:leading-6'
              />
              <ErrorMessage
                errors={errors}
                name={`packages.${index}.pcs`}
                render={({ message }) => <p className='text-red-700 font-medium text-xs inline-flex items-center'>{message}</p>}
              />
            </div>
          </div>
    
          <div className='sm:col-span-4'>
            <label htmlFor={`packages.${index}.link`} className="block text-sm font-medium leading-6 text-gray-900">
        Link to product
            </label>
            <div className="mt-2">
              <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-orange-600 hover:ring-orange-500 sm:max-w-md">
                <span className="flex select-none items-center pl-3 text-gray-500 sm:text-sm">http://</span>
                <input
                  type="text"
                  id={`packages.${index}.link`}
                  {...register(`packages.${index}.link` as const, {
                    required: false,
                    
                  })}
                  className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                  placeholder="www.example.com"
                />
                <ErrorMessage
                  errors={errors}
                  name={`packages.${index}.link`}
                  render={({ message }) => <p className='text-red-700 font-medium text-xs inline-flex items-center'>{message}</p>}
                />
              </div>
            </div>
          </div>
          <div className='sm:col-span-2'>
            <label
              htmlFor={`packages.${index}.ref`}
              className='block text-sm font-medium leading-6 text-gray-900'
            >
                Ref.no:
            </label>
            <div className='mt-2'>
              <input
                type='text'
                id={`packages.${index}.ref`}
                {...register(`packages.${index}.ref` as const)}
                className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset hover:ring-orange-500 focus:ring-orange-600 sm:text-sm sm:leading-6'
              />
              <ErrorMessage
                errors={errors}
                name={`packages.${index}.ref`}
                render={({ message }) => <p className='text-red-700 font-medium text-xs inline-flex items-center'>{message}</p>}
              />
            </div>
          </div>
  
     

          {watch('packages').length > 1 &&  <div className=' sm:col-end-7 sm:justify-self-end  sm:col-span-2  mt-3'>
            <button
              type="button"
              onClick={() => removePackage(index)}
              className="rounded bg-orange-600 px-2 py-2 text-xs font-semibold text-white shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600"
            >
         
              Delete package
            </button>
          </div>}
        </div>
        
      ))}
      <div className='sm:col-span-2 mt-3 flex gap-4 self-center'>
        <button
          type="button"
          onClick={addPackage}
          className="rounded bg-orange-600 px-2 py-2 text-xs font-semibold text-white shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600"
        >
                Add package
        </button>
        <div>
      Total packages {fields.length}
        </div>
      </div>   
    </div>

    
  
  );
};