export type CanCodes = Array<CanCode>
export type CanMalfCodes = Array<CanMalfCode>

type CanCode = {
  'objectno': string
  'objectuid': string,
  'cansignaltime': string,
  'cansignaltypename': string,
  'cansignaltypedescription': string,
  'cansignaldatatype': number,
  'cansignalvalue': number,
  'cansignalunit': number
}

type CanMalfCode = {
  'objectno': string,
  'objectuid': string
  'malfunctiontype': VehicleStatus
  'malfunctionlevel': number
}

export enum ErrorCodeLevel {
OK = 1,
WARNING = 2,
ERROR = 3,
}
export enum VehicleStatus {
  Battery = 10,
  BatteryVoltage = 20,
  Lights = 30,
  ExhaustSystem = 40,
  TyrePressure = 50,
  BrakeFluid = 60,
  CoolantLevel = 70,
  EngineOilLevel = 80,
  EngineOilPressure = 90,
  DieselParticulateFilter = 100,
  CoolantTemperature = 110,
  EngineCooling = 120,
  ABS = 130,
  BrakeSystem = 140,
  Airbag = 150,
  EPC = 160,
  ESP = 170,
  PreheatOrEngineMalfunction = 180,
  BrakePads = 190,
  EngineMalfunctionIndicatorLamp = 200,
  TransmissionFailure = 210,
  LowCoolantLevel = 220,
  BrakeFailure = 230,
  Airbag2 = 240,
  AdBlueLevel = 250,
  EngineOil = 260,
  WornBrakeLinings = 270,
  TyreFailure = 280,
  EngineOilLevelLow = 290,
  EngineEmissionSystemFailure = 300,
  BatteryChargingConditionFailure = 310,
}