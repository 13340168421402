import React from 'react';

const TermsAndConditions = () => (
  <div className="terms-and-conditions p-6 max-w-4xl mx-auto">
    <h1 className="text-center text-3xl font-bold mb-6">Terms and Conditions</h1>

    <h2 className="text-2xl font-semibold mb-4">1. Introduction</h2>
    <p className="text-justify mb-4">
      Welcome to VE Customer Portal, a web application built and provided by webLeet (&quot;we&quot;, &quot;us&quot;, &quot;our&quot;) for PACAD SP. Z O.O. (known under the brand Vintage Express&reg;). These Terms and Conditions govern your use of our website and services (the &quot;Portal&quot;), which is used for collecting shipping orders.
    </p>

    <h2 className="text-2xl font-semibold mb-4">2. Acceptance of Terms</h2>
    <p className="text-justify mb-4">
      By accessing or using our Portal, you agree to comply with and be bound by these Terms and Conditions and our Privacy Policy. If you do not agree to these terms, you should not use our services.
    </p>

    <h2 className="text-2xl font-semibold mb-4">3. Services Provided</h2>
    <p className="text-justify mb-4">
      VE Customer Portal acts as an intermediary to facilitate the collection and processing of shipping orders between users and PACAD SP. Z O.O. We do not provide the actual shipping services but provide a platform to streamline the order collection process.
    </p>

    <h2 className="text-2xl font-semibold mb-4">4. User Obligations</h2>
    <p className="text-justify mb-4">
      <strong>Accurate Information:</strong> You agree to provide accurate, current, and complete information during the registration and ordering process. This information is critical as it is used to issue invoices, and it is illegal to use fake or inaccurate data. Company or private person data must be accurate and truthful.
    </p>
    <p className="text-justify mb-4">
      <strong>Compliance:</strong> You must comply with all applicable laws and regulations while using our Portal.
    </p>

    <h2 className="text-2xl font-semibold mb-4">5. Role of webLeet and PACAD SP. Z O.O.</h2>
    <p className="text-justify mb-4">
      <strong>WebLeet&apos;s Role:</strong> webLeet provides the VE Customer Portal as a service to PACAD SP. Z O.O. and does not engage in the shipping of orders. Our role is limited to providing the technology platform.
    </p>
    <p className="text-justify mb-4">
      <strong>PACAD SP. Z O.O.&apos;s Role:</strong> PACAD SP. Z O.O. is responsible for the fulfillment and delivery of shipping orders processed through the Portal. Any disputes or issues related to shipping must be resolved directly with PACAD SP. Z O.O.
    </p>

    <h2 className="text-2xl font-semibold mb-4">6. Order Processing</h2>
    <p className="text-justify mb-4">
      <strong>Registration:</strong> Orders are registered upon receipt of full payment through the Portal.
    </p>
    <p className="text-justify mb-4">
      <strong>Delivery:</strong> PACAD SP. Z O.O. is responsible for the timely delivery of services. webLeet does not guarantee delivery times but facilitates communication between users and PACAD SP. Z O.O.
    </p>

    <h2 className="text-2xl font-semibold mb-4">7. Payments</h2>
    <p className="text-justify mb-4">
      <strong>Processing:</strong> Payments are processed through the Portal. webLeet may charge a brokerage fee for facilitating the transaction.
    </p>
    <p className="text-justify mb-4">
      <strong>Refunds:</strong> Any refund requests should be directed to PACAD SP. Z O.O. webLeet is not responsible for issuing refunds.
    </p>

    <h2 className="text-2xl font-semibold mb-4">8. Limitation of Liability</h2>
    <p className="text-justify mb-4">
      <strong>Indirect Damages:</strong> webLeet and PACAD SP. Z O.O. are not liable for any indirect, incidental, special, or consequential damages arising out of or in connection with the use of the Portal.
    </p>
    <p className="text-justify mb-4">
      <strong>Maximum Liability:</strong> Our maximum liability to you for any claims arising from your use of the Portal shall not exceed the total amount of fees you paid to us in the past six months.
    </p>

    <h2 className="text-2xl font-semibold mb-4">9. Intellectual Property</h2>
    <p className="text-justify mb-4">
      <strong>Ownership:</strong> All content on the Portal, including text, graphics, logos, and software, is the property of webLeet or its licensors and is protected by copyright and other intellectual property laws.
    </p>
    <p className="text-justify mb-4">
      <strong>Use:</strong> You may not use, reproduce, or distribute any content from the Portal without our prior written permission.
    </p>

    <h2 className="text-2xl font-semibold mb-4">10. Termination</h2>
    <p className="text-justify mb-4">
      <strong>Termination by Us:</strong> We reserve the right to suspend or terminate your access to the Portal at our discretion, without notice, for conduct that we believe violates these Terms and Conditions or is harmful to other users of the Portal.
    </p>
    <p className="text-justify mb-4">
      <strong>Termination by You:</strong> You may terminate your account at any time by contacting us. Upon termination, you must cease all use of the Portal.
    </p>

    <h2 className="text-2xl font-semibold mb-4">11. Changes to Terms</h2>
    <p className="text-justify mb-4">
      We may update these Terms and Conditions from time to time. Any changes will be posted on our website, and your continued use of the Portal after such changes have been posted constitutes your agreement to the updated terms.
    </p>

    <h2 className="text-2xl font-semibold mb-4">12. Governing Law</h2>
    <p className="text-justify mb-4">
      These Terms and Conditions are governed by and construed in accordance with the laws of Poland. Any disputes arising out of or related to these terms shall be subject to the exclusive jurisdiction of the courts of Poland.
    </p>

    <h2 className="text-2xl font-semibold mb-4">13. Contact Information</h2>
    <p className="text-justify mb-4">
      For any questions or concerns regarding these Terms and Conditions or problems with Customer Portal, please contact us at:
    </p>
    <address className="text-justify mb-4">
      webLeet<br />
      ul. Barcza 50B/59<br />
      10-685 Olsztyn<br />
      Poland<br />
      VAT ID: PL7393016265<br />
      <a href='mailto:contact@webleet.eu' className='hover:text-orange-600'>contact@webleet.eu</a>
    </address>
  </div>
);

export default TermsAndConditions;
