import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Contact } from '../../types/Order';
import { useGetCountries } from '../../hooks/useCountries';
import { ErrorMessage } from '@hookform/error-message';
export const NewContact = () => {
  const { register, handleSubmit, formState: { errors } } = useForm<Partial<Contact>>();
  const { countries } = useGetCountries();
  const navigate = useNavigate();

  const onSubmit = (data: Partial<Contact>) => {
    console.log(data);

    navigate('contacts');
  }; 

  
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className='space-y-12'>
        <div className='grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-5'>
          <div>
            <h2 className='text-base font-semibold leading-7 text-gray-900'>
              New Contact
            </h2>
            <p className='mt-1 text-sm leading-6 text-gray-600'>
              Enter new contact details.
            </p>
          </div>

          <div className='grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-4'>
            <div className='sm:col-span-3'>
              <label
                htmlFor='name'
                className='block text-sm font-medium leading-6 text-gray-900'
              >
                First name
              </label>
              <div className='mt-2'>
                <input
                  type='text'
                  id='name'
                  autoComplete='given-name'
                  {...register('name', { required: 'Name is required' })}
                  className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset  hover:ring-orange-500 focus:ring-orange-600 sm:text-sm sm:leading-6'
                />
                <ErrorMessage
                  errors={errors}
                  name='name'
                  render={({ message }) => <p className='text-red-700 font-medium text-xs inline-flex items-center'>{message}</p>}
                />
              </div>
            </div>

            <div className='sm:col-span-3'>
              <label
                htmlFor='surname'
                className='block text-sm font-medium leading-6 text-gray-900'
              >
                Last name
              </label>
              <div className='mt-2'>
                <input
                  type='text'
                  id='surname'
                  autoComplete='family-name'
                  {...register('surname', { required: 'Surname is required' })}
                  className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset hover:ring-orange-500 focus:ring-orange-600 sm:text-sm sm:leading-6'
                />
                <ErrorMessage
                  errors={errors}
                  name='surname'
                  render={({ message }) => <p className='text-red-700 font-medium text-xs inline-flex items-center'>{message}</p>}
                />
              </div>
            </div>
            <div className='sm:col-span-4'>
              <label
                htmlFor='company'
                className='block text-sm font-medium leading-6 text-gray-900'
              >
                Company
              </label>
              <div className='mt-2'>
                <input
                  id='company'
                  type='text'
                  autoComplete='organization'
                  {...register('company')}
                  className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset hover:ring-orange-500 focus:ring-orange-600 sm:text-sm sm:leading-6'
                />
              </div>
            </div>
            <div className='sm:col-span-3'>
              <label
                htmlFor='email'
                className='block text-sm font-medium leading-6 text-gray-900'
              >
                Email address
              </label>
              <div className='mt-2'>
                <input
                  id='email'
                  type='email'
                  autoComplete='email'
                  {...register('email', {
                    required: 'E-mail is required',
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: 'Invalid email address'
                    } 
                  })}
                  className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset hover:ring-orange-500 focus:ring-orange-600 sm:text-sm sm:leading-6'
                />
                <ErrorMessage
                  errors={errors}
                  name='email'
                  render={({ message }) => <p className='text-red-700 font-medium text-xs inline-flex items-center'>{message}</p>}
                />
              </div>
            </div>
            <div className='sm:col-span-3'>
              <label
                htmlFor='phone'
                className='block text-sm font-medium leading-6 text-gray-900'
              >
                Phone no.
              </label>
              <div className='mt-2'>
                <input
                  id='phone'
                  type='tel'
                  autoComplete='tel'
                  {...register('phone', { required: 'Phone is required' })}
                  className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset hover:ring-orange-500 focus:ring-orange-600 sm:text-sm sm:leading-6'
                />
                <ErrorMessage
                  name='phone'
                  errors={errors}
                  render={({ message }) => <p className='text-red-700 font-medium text-xs inline-flex items-center'>{message}</p>}
                />
              </div>
            </div>

            <div className='sm:col-span-4'>
              <label
                htmlFor='street'
                className='block text-sm font-medium leading-6 text-gray-900'
              >
                Street address
              </label>
              <div className='mt-2'>
                <input
                  type='text'
                  placeholder='You can paste full address here...'
                  id='street'
                  autoComplete='address-line1'
                  {...register('street', { required: 'Street is required' })}
                  className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset hover:ring-orange-500 focus:ring-orange-600 sm:text-sm sm:leading-6'
                />
                <ErrorMessage
                  name='street'
                  errors={errors}
                  render={({ message }) => <p className='text-red-700 font-medium text-xs inline-flex items-center'>{message}</p>}
                />
              </div>
            </div>

            <div className='sm:col-span-1'>
              <label
                htmlFor='buildingNo'
                className='block text-sm font-medium leading-6 text-gray-900'
              >
                Building no.
              </label>
              <div className='mt-2'>
                <input
                  type='text'
                  id='buildingNo'
                  autoComplete='address-line2'
                  {...register('buildingNo', { required: 'Building no is required' })}
                  className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset hover:ring-orange-500 focus:ring-orange-600 sm:text-sm sm:leading-6'
                />
                <ErrorMessage
                  name='buildingNo'
                  errors={errors}
                  render={({ message }) => <p className='text-red-700 font-medium text-xs inline-flex items-center'>{message}</p>}
                />
              </div>
            </div>

            <div className='sm:col-span-1'>
              <label
                htmlFor='apartmentNo'
                className='block text-sm font-medium leading-6 text-gray-900'
              >
                Apt
              </label>
              <div className='mt-2'>
                <input
                  type='text'
                  id='apartmentNo'
                  autoComplete='address-line3'
                  {...register('apartmentNo')}
                  className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset hover:ring-orange-500 focus:ring-orange-600 sm:text-sm sm:leading-6'
                />
              </div>
            </div>

            <div className='col-span-full'>
              <label
                htmlFor='notes'
                className='block text-sm font-medium leading-6 text-gray-900'
              >
                Notes
              </label>
              <div className='mt-2'>
                <input
                  type='text'
                  placeholder='ex.: you have to use the back door'
                  id='notes'
                  {...register('notes')}
                  className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset hover:ring-orange-500 focus:ring-orange-600 sm:text-sm sm:leading-6'
                />
              </div>
            </div>

            <div className='sm:col-span-2 sm:col-start-1'>
              <label
                htmlFor='city'
                className='block text-sm font-medium leading-6 text-gray-900'
              >
                City
              </label>
              <div className='mt-2'>
                <input
                  type='text'
                  id='city'
                  autoComplete='address-level2'
                  {...register('city', { required: 'City is required' })}
                  className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset hover:ring-orange-500 focus:ring-orange-600 sm:text-sm sm:leading-6'
                />
                <ErrorMessage
                  name='city'
                  errors={errors}
                  render={({ message }) => <p className='text-red-700 font-medium text-xs inline-flex items-center'>{message}</p>}
                />
              </div>
            </div>

            <div className='sm:col-span-2'>
              <label
                htmlFor='state'
                className='block text-sm font-medium leading-6 text-gray-900'
              >
                State / Province
              </label>
              <div className='mt-2'>
                <input
                  type='text'
                  id='state'
                  autoComplete='address-level1'
                  {...register('state')}
                  className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset hover:ring-orange-500 focus:ring-orange-600 sm:text-sm sm:leading-6'
                />
              </div>
            </div>

            <div className='sm:col-span-2'>
              <label
                htmlFor='postCode'
                className='block text-sm font-medium leading-6 text-gray-900'
              >
                ZIP / Postal code
              </label>
              <div className='mt-2'>
                <input
                  type='text'
                  id='postCode'
                  autoComplete='postal-code'
                  {...register('postCode', { required: 'Post code is required' })}
                  className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset hover:ring-orange-500 focus:ring-orange-600 sm:text-sm sm:leading-6'
                />
                <ErrorMessage
                  name='postCode'
                  errors={errors}
                  render={({ message }) => <p className='text-red-700 font-medium text-xs inline-flex items-center'>{message}</p>}
                />
              </div>
            </div>
            <div className='sm:col-span-3'>
              <label
                htmlFor='country'
                className='block text-sm font-medium leading-6 text-gray-900'
              >
                Country
              </label>
              <div className='mt-2'>
                <select
                  id='country'
                  defaultValue={''}
                  autoComplete='country-name'
                  {...register('country', { required: 'Country is required' })}
                  className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset hover:ring-orange-500 focus:ring-orange-600 sm:max-w-xs sm:text-sm sm:leading-6'
                >
                  <option value={''} disabled >-- Choose country --</option>
                  {countries && countries.sort((a, b) => a.countryName.localeCompare(b.countryName)).map(country => (
                    <option key={country.id}>{country.countryName}</option>
                  ))}
                </select>
                <ErrorMessage
                  name='country'
                  errors={errors}
                  render={({ message }) => <p className='text-red-700 font-medium text-xs inline-flex items-center'>{message}</p>}
                />
              </div>
            </div>
          </div>
        </div>
       
     
      </div>

      <div className='mt-6 flex items-center justify-end gap-x-6'>
        <button
          type='button'
          className='text-sm font-semibold leading-6 text-gray-900'
        >
          Cancel
        </button>
        <button
          type='submit'
          className='rounded-md bg-orange-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600'
        >
          Save
        </button>
      </div>
    </form>
  );
};
