import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';


export const PaymentFail = () => {
  const [ seconds, setSeconds ] = useState(10);
  const navigate = useNavigate();
  
  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds(prevSeconds => prevSeconds - 1);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  // Handle navigation
  useEffect(() => {
    if (seconds === 0) {
      navigate('/orders');
    }
  }, [ seconds ]); // Depend on `seconds` and `navigate`


  return (
    <div className="rounded-md bg-yellow-50 p-4">
      <div className="flex justify-center">
        <div className="flex-shrink-0">
          <ExclamationTriangleIcon aria-hidden="true" className="h-5 w-5 text-yellow-400" />
        </div>
        <div className="ml-3">
          <h3 className="text-sm font-medium text-yellow-800">Payment not done!</h3>
          <div className="mt-2 text-sm text-yellow-700">
            <p>
              There was a problem with processing your payment. Please try again later.
            </p>
            <p>
Redirecting to main page in {seconds} {seconds !== 1 ? 'seconds' : 'second'}           </p>
          </div>
        </div>
      </div>
    </div>
  );
};