import { useQuery } from '@tanstack/react-query';
import { queryKeys } from '../react-query/query-keys';
import { GetContactsReturnData, contactsService } from '../api/contacts';
import { useAuthContext } from '../context/AuthProvider';

export const useFetchContacts = (params: string) => {
  const { activeUser } = useAuthContext();


  const fallbackData: GetContactsReturnData = {
    rows: [],
    count: 0
  };
  const { data = fallbackData, isLoading } = useQuery({
    enabled: !!activeUser,
    queryKey: [ queryKeys.userContacts, activeUser?.id, params ],
    queryFn: () => contactsService.getContacts(params)
  });

  console.log('contctsData:', );
  return {
    contacts: data.rows,
    count: data.count,
    isLoading
  };
};