import { Fragment, useEffect, useState } from 'react';
import { Dialog, Disclosure, Transition } from '@headlessui/react';
import {
  ChevronRightIcon,
  Cog6ToothIcon,
  DocumentIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';

import { NavLink } from 'react-router-dom';
import cn from 'classnames';
import { userNav, proUserNav, logisticsNav, adminNav, employeeNav } from '../../data/nav';
import { useAuthContext } from '../../context/AuthProvider';



type Sidebar = {
  sidebarOpen: boolean;
  handleCloseSidebar: () => void;
};

export const Sidebar = ({ sidebarOpen, handleCloseSidebar }: Sidebar) => {
  const { activeUser } = useAuthContext();
  const [ navigation, setNavigation ] = useState(userNav);

  useEffect(() => {
    if (!activeUser) {
      return;
    }

    if (activeUser.role === 'prouser') {
      setNavigation(proUserNav);
    } else if (activeUser.role === 'admin' ) {
      setNavigation(adminNav);
    } else if (activeUser.role === 'logistics') {
      setNavigation(logisticsNav);
    }else if (activeUser.role === 'employee') {
      setNavigation(employeeNav);
    }

 
  }, [ activeUser ]);


  let logo;

  if (activeUser?.logo) {
    logo = activeUser.logo;
  }

  const BASE_URL=process.env.REACT_APP_STATIC_URL;

  const currentNav = (name: string) => {
    return setNavigation((prev) =>
      prev.map((navEl) => {
        if (navEl.name === name) {
          return {
            ...navEl,
            current: true,
          };
        }
        return {
          ...navEl,
          current: false,
        };
      })
    );
  };

    
  return (
    <>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as='div'
          className='relative z-50 lg:hidden'
          onClose={handleCloseSidebar}
        >
          <Transition.Child
            as={Fragment}
            enter='transition-opacity ease-linear duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='transition-opacity ease-linear duration-300'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <div className='fixed inset-0 bg-gray-900/80' />
          </Transition.Child>

          <div className='fixed inset-0 flex'>
            <Transition.Child
              as={Fragment}
              enter='transition ease-in-out duration-300 transform'
              enterFrom='-translate-x-full'
              enterTo='translate-x-0'
              leave='transition ease-in-out duration-300 transform'
              leaveFrom='translate-x-0'
              leaveTo='-translate-x-full'
            >
              <Dialog.Panel className='relative mr-16 flex w-full max-w-xs flex-1'>
                <Transition.Child
                  as={Fragment}
                  enter='ease-in-out duration-300'
                  enterFrom='opacity-0'
                  enterTo='opacity-100'
                  leave='ease-in-out duration-300'
                  leaveFrom='opacity-100'
                  leaveTo='opacity-0'
                >
                  <div className='absolute left-full top-0 flex w-16 justify-center pt-5'>
                    <button
                      type='button'
                      className='-m-2.5 p-2.5'
                      onClick={handleCloseSidebar}
                    >
                      <span className='sr-only'>Close sidebar</span>
                      <XMarkIcon
                        className='h-6 w-6 text-white'
                        aria-hidden='true'
                      />
                    </button>
                  </div>
                </Transition.Child>
                {/* Sidebar component, swap this element with another sidebar if you like */}
                <div className='flex grow flex-col gap-y-6 overflow-y-auto bg-white px-6 pb-4'>
                  <div className='flex h-16 shrink-0 gap-x-6 items-center mt-2'>
                    <img
                      className='h-14 w-auto'
                      src={BASE_URL + '/ve_logo.png'}
                      alt='VE logo'
                    />
                    {logo && 
                         <img
                           className='h-14 w-auto'
                           src={BASE_URL + `/clientLogos/${logo}`}
                           alt='Client logo'
                         />
                    }
               
                  </div>
                  <nav className='flex flex-1 flex-col'>
                    <ul role='list' className='flex flex-1 flex-col gap-y-7'>
                      <li>
                        <ul role='list' className='-mx-2 space-y-1'>
                          {navigation.map((item) => (
                            <li key={item.name}>
                              {item.children && 
                                  <Disclosure as="div">
                                    {({ open }) => (
                                      <>
                                        <Disclosure.Button
                                          className='group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold text-gray-700 hover:text-orange-600 hover:bg-gray-50'
                                        >
                                          <item.icon
                                            className='h-6 w-6 shrink-0'
                                            aria-hidden='true'
                                          />
                                          {item.name}
                                          <ChevronRightIcon
                                            className={cn(
                                              open ? 'rotate-90 text-gray-500' : 'text-gray-400',
                                              'ml-auto h-5 w-5 shrink-0'
                                            )}
                                            aria-hidden="true"
                                          />
                                        </Disclosure.Button>
                                        <Disclosure.Panel as="ul" className="mt-1 px-2">
                                          {item.children && item.children.map((subItem) => (
                                            <li key={subItem.name}>
                                              {/* 44px */}
                                              <Disclosure.Button
                                                as="a"
                                                // href={subItem.href}
                                                className='block rounded-md  pr-2 pl-9 text-sm leading-6 text-gray-700'
                                              >
                                                <NavLink  to={`/${subItem.link}`}
                                                  onClick={() => {
                                                    setTimeout(() => {
                                                      handleCloseSidebar();
                                                    }, 200);
                                                    currentNav(subItem.name);
                                                  }}
                                                  className={({ isActive }) => cn('group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold', {
                                                    'bg-gray-50 text-orange-600': isActive,
                                                    'text-gray-700 hover:text-orange-600 hover:bg-gray-50': !isActive
                                                  }
                                                  )}
                                                >
                                                  <subItem.icon
                                                    className='h-6 w-6 shrink-0'
                                                    aria-hidden='true'
                                                  />
                                                  {subItem.name}
                                                </NavLink>
                                                {/* {subItem.name} */}
                                              </Disclosure.Button>
                                            </li>
                                          ))}
                                        </Disclosure.Panel>
                                      </>
                                    )}
                                  </Disclosure>
                              }
                              {!item.children && 
                              <NavLink
                                to={`/${item.link}`}
                                onClick={() => {
                                  setTimeout(() => {
                                    handleCloseSidebar();
                                  }, 200);
                                  currentNav(item.name);
                                }}
                                className={({ isActive }) => cn('group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold', {
                                  'bg-gray-50 text-orange-600': isActive,
                                  'text-gray-700 hover:text-orange-600 hover:bg-gray-50': !isActive
                                }
                                )}
                              >
                                <item.icon
                                  className='h-6 w-6 shrink-0'
                                  aria-hidden='true'
                                />
                                {item.name}
                             
                              </NavLink>
                              }
                            </li>
                          ))}
                        </ul>
                      </li>
                      <li>
                      </li>
                      {activeUser && activeUser.role === 'admin' && 

                          <li className='mt-auto'>
                            <NavLink
                              to={'/settings'}
                              onClick={() => {
                                setTimeout(() => {
                                  handleCloseSidebar();
                                }, 200);
                              }}
                              className={({ isActive }) => cn('group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold', {
                                'bg-gray-50 text-orange-600': isActive,
                                'text-gray-700 hover:text-orange-600 hover:bg-gray-50': !isActive
                              }
                              )}
                            >
                              <Cog6ToothIcon
                                className='h-6 w-6 shrink-0 group-hover:text-orange-600'
                                aria-hidden='true'
                              />
                              Settings
                            </NavLink>
                          </li>

                      }
                      <li className='mt-auto'>
                        <NavLink
                          to={'/terms'}
                          onClick={() => {
                            setTimeout(() => {
                              handleCloseSidebar();
                            }, 200);
                          }}
                          className={({ isActive }) => cn('group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold', {
                            'bg-gray-50 text-orange-600': isActive,
                            'text-gray-700 hover:text-orange-600 hover:bg-gray-50': !isActive
                          }
                          )}
                        >
                          <DocumentIcon
                            className='h-6 w-6 shrink-0 group-hover:text-orange-600'
                            aria-hidden='true'
                          />
                              Terms & Conditions
                        </NavLink>
                      </li>

                    </ul>
                  </nav>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}
      <div className='hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-60 lg:flex-col'>
        <div className='flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white px-6 pb-4'>
          <div className='flex h-16 shrink-0 gap-x-1 mt-2 items-center'>
            <img
              className='h-14 w-auto'
              src={BASE_URL + '/ve_logo.png'}
              alt='VE logo'
            />
            {logo && 
                 <img
                   className='h-14 w-auto'
                   src={BASE_URL + `/clientLogos/${logo}`}
                   alt='Client logo'
                 />
            }
       
          </div>
          <nav className='flex flex-1 flex-col'>
            <ul role='list' className='flex flex-1 flex-col gap-y-7'>
              <li>
                <ul role='list' className='-mx-2 space-y-1'>
                  {navigation.map((item) => (
                    <li key={item.name}>
                      {item.children && 
                                  <Disclosure as="div">
                                    {({ open }) => (
                                      <>
                                        <Disclosure.Button
                                          className='group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold text-gray-700 hover:text-orange-600 hover:bg-gray-50'
                                        >
                                          <item.icon
                                            className='h-6 w-6 shrink-0'
                                            aria-hidden='true'
                                          />
                                          {item.name}
                                          <ChevronRightIcon
                                            className={cn(
                                              open ? 'rotate-90 text-gray-500' : 'text-gray-400',
                                              'ml-auto h-5 w-5 shrink-0'
                                            )}
                                            aria-hidden="true"
                                          />
                                        </Disclosure.Button>
                                        <Disclosure.Panel as="ul" className="mt-1 px-2">
                                          {item.children && item.children.map((subItem) => (
                                            <li key={subItem.name}>
                                              {/* 44px */}
                                              <Disclosure.Button
                                                as="a"
                                                // href={subItem.href}
                                                className='block rounded-md  pr-2 pl-9 text-sm leading-6 text-gray-700'
                                              >
                                                <NavLink  to={`/${subItem.link}`}
                                                  onClick={() => {
                                                    setTimeout(() => {
                                                      handleCloseSidebar();
                                                    }, 200);
                                                    currentNav(subItem.name);
                                                  }}
                                                  className={({ isActive }) => cn('group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold', {
                                                    'bg-gray-50 text-orange-600': isActive,
                                                    'text-gray-700 hover:text-orange-600 hover:bg-gray-50': !isActive
                                                  }
                                                  )}
                                                >
                                                  <subItem.icon
                                                    className='h-6 w-6 shrink-0'
                                                    aria-hidden='true'
                                                  />
                                                  {subItem.name}
                                                </NavLink>
                                                {/* {subItem.name} */}
                                              </Disclosure.Button>
                                            </li>
                                          ))}
                                        </Disclosure.Panel>
                                      </>
                                    )}
                                  </Disclosure>
                      }
                      {!item.children && 
                              <NavLink
                                to={`/${item.link}`}
                                onClick={() => {
                                  setTimeout(() => {
                                    handleCloseSidebar();
                                  }, 200);
                                  currentNav(item.name);
                                }}
                                className={({ isActive }) => cn('group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold', {
                                  'bg-gray-50 text-orange-600': isActive,
                                  'text-gray-700 hover:text-orange-600 hover:bg-gray-50': !isActive
                                }
                                )}
                              >
                                <item.icon
                                  className='h-6 w-6 shrink-0'
                                  aria-hidden='true'
                                />
                                {item.name}
                             
                              </NavLink>
                      }
                    </li>
                  ))}
                </ul>
              </li>
              <li>
                {/* <div className='text-xs font-semibold leading-6 text-gray-400'>
                            Your teams
                          </div> */}
                {/* <ul role='list' className='-mx-2 mt-2 space-y-1'>
                            {teams.map((team) => (
                              <li key={team.name}>
                                <a
                                  href={team.href}
                                  className={cn(
                                    team.current
                                      ? 'bg-gray-50 text-orange-600'
                                      : 'text-gray-700 hover:text-orange-600 hover:bg-gray-50',
                                    'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                                  )}
                                >
                                  <span
                                    className={cn(
                                      team.current
                                        ? 'text-orange-600 border-orange-600'
                                        : 'text-gray-400 border-gray-200 group-hover:border-orange-600 group-hover:text-orange-600',
                                      'flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border text-[0.625rem] font-medium bg-white'
                                    )}
                                  >
                                    {team.initial}
                                  </span>
                                  <span className='truncate'>{team.name}</span>
                                </a>
                              </li>
                            ))}
                          </ul> */}
              </li>
              {activeUser && activeUser.role === 'admin' && 

                          <li className='mt-auto'>
                            <NavLink
                              to={'/settings'}
                              className={({ isActive }) => cn('group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold', {
                                'bg-gray-50 text-orange-600': isActive,
                                'text-gray-700 hover:text-orange-600 hover:bg-gray-50': !isActive
                              }
                              )}
                            >
                              <Cog6ToothIcon
                                className='h-6 w-6 shrink-0 group-hover:text-orange-600'
                                aria-hidden='true'
                                
                              />
                              Settings
                            </NavLink>
                          </li>

              }
              <li className='mt-auto'>
                <NavLink
                  to={'/terms'}
                  className={({ isActive }) => cn('group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold', {
                    'bg-gray-50 text-orange-600': isActive,
                    'text-gray-700 hover:text-orange-600 hover:bg-gray-50': !isActive
                  }
                  )}
                >
                  <DocumentIcon
                    className='h-6 w-6 shrink-0 group-hover:text-orange-600'
                    aria-hidden='true'
                                
                  />
                              Terms & Conditions
                </NavLink>
              </li>   

            </ul>
          </nav>
        </div>
      </div>
    </>
  );
};
