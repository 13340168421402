import {
  BanknotesIcon,
  BuildingOfficeIcon,
  CurrencyEuroIcon,
  DocumentDuplicateIcon,
  GlobeEuropeAfricaIcon,
  IdentificationIcon,
  RectangleGroupIcon,
  Squares2X2Icon,
  SquaresPlusIcon,
  TruckIcon,
  UsersIcon,
} from '@heroicons/react/24/outline';

type NavType = {
  name: string
  icon: typeof BanknotesIcon,
  current: boolean,
  link: string
  children?: NavType[],
}


export const userNav: NavType[] = [
  {
    name: 'Orders',
    icon: Squares2X2Icon,
    current: false,
    link: 'orders',
  },
  {
    name: 'New Order',
    icon: SquaresPlusIcon,
    current: false,
    link: 'new-order',
  },
  // {
  //   name: 'Addressbook',
  //   icon: IdentificationIcon,
  //   current: false,
  //   link: 'contacts',
  // },
  // {
  //   name: 'Invoices',
  //   icon: BanknotesIcon,
  //   current: false,
  //   link: 'invoices',
  // }

];

export const proUserNav: NavType[] = [
  {
    name: 'Orders',
    icon: Squares2X2Icon,
    current: false,
    link: 'orders',
  },
  {
    name: 'New Order',
    icon: SquaresPlusIcon,
    current: false,
    link: 'new-order',
  },
  {
    name: 'Addressbook',
    icon: IdentificationIcon,
    current: false,
    link: 'contacts',
  },
  {
    name: 'Invoices',
    icon: BanknotesIcon,
    current: false,
    link: 'invoices',
  },
  {
    name: 'Documents',
    icon: DocumentDuplicateIcon,
    current: false,
    link: 'documents',
  },

  {
    name: 'Price Calculator',
    icon: CurrencyEuroIcon,
    current: false,
    link: 'price-calc',
  }
];

export const adminNav: NavType[] = [
  {
    name: 'Map',
    icon: GlobeEuropeAfricaIcon,
    current: false,
    link: 'map',
  },
  {
    name: 'Orders',
    icon: Squares2X2Icon,
    current: false,
    link: 'orders',
  },
  {
    name: 'New Order',
    icon: SquaresPlusIcon,
    current: false,
    link: 'new-order',
  },
  {
    name: 'Addressbook',
    icon: IdentificationIcon,
    current: false,
    link: 'contacts',
  },
  {
    name: 'Invoices',
    icon: BanknotesIcon,
    current: false,
    link: 'invoices',
  },
  {
    name: 'Resources',
    icon: RectangleGroupIcon,
    current: false,
    link: 'recources',
    children: [
      {
        name: 'Trucks',
  
        icon: TruckIcon,
        current: false,
        link: 'vans',
      },
      {
        name: 'Drivers',
        icon: UsersIcon,
        current: false,
        link: 'drivers',
      },
      {
        name: 'Warehouses',
        icon: BuildingOfficeIcon,
        current: false,
        link: 'drivers',
      },
    ],
  },

  {
    name: 'Clients',
    icon: UsersIcon,
    current: false,
    link: 'clients',
  },
  {
    name: 'Documents',
    icon: DocumentDuplicateIcon,
    current: false,
    link: 'documents',
  },
  {
    name: 'Price Lists',
    icon: CurrencyEuroIcon,
    current: false,
    link: 'price-list',
  },
  {
    name: 'Price Calculator',
    icon: CurrencyEuroIcon,
    current: false,
    link: 'price-calc',
  }
];

export const logisticsNav: NavType[] = [
  {
    name: 'Map',
    icon: GlobeEuropeAfricaIcon,
    current: false,
    link: 'map',
  },
  {
    name: 'Orders',
    icon: Squares2X2Icon,
    current: false,
    link: 'orders',
  },
  {
    name: 'New Order',
    icon: SquaresPlusIcon,
    current: false,
    link: 'new-order',
  },
  {
    name: 'Addressbook',
    icon: IdentificationIcon,
    current: false,
    link: 'contacts',
  },
  {
    name: 'Trucks',
    icon: TruckIcon,
    current: false,
    link: 'vans',
  },
  {
    name: 'Drivers',
    icon: UsersIcon,
    current: false,
    link: 'drivers',
  },
  {
    name: 'Clients',
    icon: BuildingOfficeIcon,
    current: false,
    link: 'clients',
  },
  {
    name: 'Invoices',
    icon: BanknotesIcon,
    current: false,
    link: 'invoices',
  },
  {
    name: 'Documents',
    icon: DocumentDuplicateIcon,
    current: false,
    link: 'documents',
  },
  {
    name: 'Price Calculator',
    icon: CurrencyEuroIcon,
    current: false,
    link: 'price-calc',
  }
];

export const employeeNav: NavType[] = [

  {
    name: 'Documents',
    icon: DocumentDuplicateIcon,
    current: false,
    link: 'documents',
  },
  {
    name: 'Routes',
    icon: GlobeEuropeAfricaIcon,
    current: false,
    link: 'routes',
  },

];

