import { createContext, useContext } from 'react';
import { useAuth } from '../hooks/useAuth';

type AuthContextType = ReturnType<typeof useAuth>
const AuthContext = createContext<AuthContextType | undefined>( undefined );

type AuthProviderProps = {
    children: React.ReactNode
}

export const AuthProvider = ( { children }: AuthProviderProps ) => {
  const { ...args } = useAuth();
  return (
    <AuthContext.Provider value={{ ...args }}>
      {children}
    </AuthContext.Provider>
  );

};

export const useAuthContext = () => {
  const values  = useContext( AuthContext );

  if ( !values ) {
    throw Error( 'AuthContext must be within AuthProvider' );
  }

  return values;
};