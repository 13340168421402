import { CheckCircleIcon } from '@heroicons/react/24/outline';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';


export const PaymentSuccess = () => {
  const [ seconds, setSeconds ] = useState(5);
  const navigate = useNavigate();
  
  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds(prevSeconds => prevSeconds - 1);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  // Handle navigation
  useEffect(() => {
    if (seconds === 0) {
      navigate('/orders');
    }
  }, [ seconds ]); // Depend on `seconds` and `navigate`


  return (
    <div className="rounded-md bg-green-50 p-4">
      <div className="flex justify-center">
        <div className="flex-shrink-0">
          <CheckCircleIcon aria-hidden="true" className="h-5 w-5 text-green-400" />
        </div>
        <div className="ml-3">
          <h3 className="text-sm font-medium text-green-800">Payment completed succesfully</h3>
          <div className="mt-2 text-sm text-green-700">
            <p>{`You will receive confirmation on your email. Redirection to main page in ${seconds}`}</p>
          </div>
          {/* <div className="mt-4">
            <div className="-mx-2 -my-1.5 flex">
              <button
                type="button"
                className="rounded-md bg-green-50 px-2 py-1.5 text-sm font-medium text-green-800 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2 focus:ring-offset-green-50"
              >
                  View status
              </button>
              <button
                type="button"
                className="ml-3 rounded-md bg-green-50 px-2 py-1.5 text-sm font-medium text-green-800 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2 focus:ring-offset-green-50"
              >
                  Dismiss
              </button>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};