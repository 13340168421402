import { XCircleIcon } from '@heroicons/react/20/solid';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';



export const Unathorized = () => {
  const [ seconds, setSeconds ] = useState(3);
  const navigate = useNavigate();

  useEffect(() => {
    if (seconds <= 0) {
      navigate('/');
      return; // Exit the effect once navigation is triggered
    }

    // Set up the interval
    const interval = setInterval(() => {
      setSeconds(prev => prev - 1);
    }, 1000);

    // Clear interval on component unmount
    return () => clearInterval(interval);
  }, [ seconds, navigate ]); 



  return (
    <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 flex h-svh items-center justify-center ">
      <div className="mx-auto max-w-3xl">
        <div className="rounded-md bg-red-50 p-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
            </div>
            <div className="ml-3">
              <h3 className="text-sm font-medium text-red-800">Unathorized or site doesn&apos;t exist</h3>
              <div className="mt-2 text-sm text-red-700">
                <ul role="list" className="list-disc space-y-1 pl-5">
                  <li>{`You will be redirected in ${seconds} seconds`} </li>
            
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
