// import { CheckCircleIcon } from '@heroicons/react/20/solid';
import { Dialog, Transition } from '@headlessui/react';
import { CheckIcon } from '@heroicons/react/24/outline';
import { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// import { App } from '../../../App';

export const AccountCreated = () => {
  const [ open, setOpen ] = useState(true);
  const navigate = useNavigate();
  useEffect(() => {
    
    setTimeout(() => {
      navigate('/employee-login');
    }, 5000);
  },[]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                <div>
                  <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                    <CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                    Account created
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                    Confirmation link sent. Please confirm your account and sign in
                      </p>
                    </div>
                  </div>
                </div>
                {/* <div className="mt-5 sm:mt-6">
                <button
                  type="button"
                  className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  onClick={() => setOpen(false)}
                >
                  Go back to dashboard
                </button>
              </div> */}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};



// <div className="rounded-md bg-green-50 p-4">
// <div className="flex">
//   <div className="flex-shrink-0">
//     <CheckCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" />
//   </div>
//   <div className="ml-3">
//     <p className="text-sm font-medium text-green-800">Confirmation link sent. Please confirm your account and sign in</p>
//   </div>
//   {/* <div className="ml-auto pl-3">
//     <div className="-mx-1.5 -my-1.5">
//       <button
//         type="button"
//         className="inline-flex rounded-md bg-green-50 p-1.5 text-green-500 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2 focus:ring-offset-green-50"
//       >
//         <span className="sr-only">Dismiss</span>
//         <XMarkIcon className="h-5 w-5" aria-hidden="true" />
//       </button>
//     </div>
//   </div> */}
// </div>
// </div>