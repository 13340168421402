import { apiClient } from '../services/axiosClient';
import { User } from '../types/User';
// import { Order } from '../types/Order';

// const baseUrl = process.env.REACT_APP_API_URL as string;

const getUsers = async (params: string) => {
  params = params ? '?' + params : '';

  try {
    const data =  await apiClient.get('/users' + params);

    return data;

    
  } catch (error) {
    console.log('Problem downloading users');
  }
 
};

const getClients = async (params: string): Promise<User[]> => {
  params = params ? '?' + params : '';
  try {
    const { data } =  await apiClient.get('/clients' + params);

    return data;

  } catch (error) {
    console.log('Problem downloading clients');
    throw new Error('Problem downloading clients');
  }
 
};

const getUser = async (userId: string): Promise<User> => {
  try {
    const { data } = await apiClient.get(`/users/${userId}`);
    return data.user;
  } catch (e) {
    console.log('Problem downloading user');
    throw new Error('Problem downloading user');
  }
};


const updateUser = async (user: Partial<User>):Promise<User> => {
  console.log('updetUser data: ', user);
  console.log('userId: ', user.id);
  try {
    const { data } = await apiClient.patch(`/users/${user.id}`, user);

    return data;
  } catch (e) {
    throw new Error('Problem updating user');
  }
};

export type PayerResponseData = {
  rows: User[],
  count: number
}

const getAllPayers = async (): Promise<PayerResponseData> => {
  try {
    const { data } = await apiClient.get('/users?role=user');
    return data;
  } catch (e) {
    console.log(e);
    throw new Error('Problem downloading payers');
  }
};
export const usersService = {
  getClients,
  getUser,
  getUsers,
  getAllPayers,
  updateUser
};