import { useQuery } from '@tanstack/react-query';
import { queryKeys } from '../react-query/query-keys';
import { countriesService } from '../api/countries';

export const useGetCountries = () => {
  const { data: countries, isLoading } = useQuery({
    queryKey: [ queryKeys.countries ],
    queryFn: countriesService.getCountries
  });

  return {
    countries,
    isLoading
  };
};