import {  Navigate, Outlet } from 'react-router-dom';
import { useAuthContext } from '../../context/AuthProvider';
// import { useState } from 'react';

type ProtecteRouteProps = {
  allowedRoles: Array<string>
}

export const ProtectedRoute = ({ allowedRoles }: ProtecteRouteProps) => {
  const { activeUser, logging, isAuthChecking } = useAuthContext(); // Use the AuthContext
  // const location = useLocation();
  
  if (logging || isAuthChecking  ) {
    // Show a loader while authentication status is being checked
    return null;
  }
  
  if (!activeUser) {
    // If the user is not logged in, redirect to the login page
    return <Navigate to="/login"  />;
  }
  
  
  const userHasRequiredRole = allowedRoles.includes(activeUser.role);

  if (!userHasRequiredRole) {
    // If the user doesn't have the required role, redirect to an unauthorized page or similar
    return <Navigate to="/unauthorized" />;
  }
  
  return <Outlet />; // Render children routes if the user is authenticated and has the required role
  

 
};
