import React from 'react';
// import ReactDOM from 'react-dom/client';
import './index.css';
import { AuthProvider } from './context/AuthProvider';
import { App } from './App';
import { TrucksProvider } from './context/TrucksProvider';
import { createRoot } from 'react-dom/client';
import { QueryClientProvider } from '@tanstack/react-query';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { queryClient } from './react-query/queryClient';


const container = document.getElementById('root');
const root = createRoot(container!); 

root.render(
  <React.StrictMode>
    <AuthProvider>
      <TrucksProvider>
        <QueryClientProvider client={queryClient}>
          <App />
          <ToastContainer/>
          <ReactQueryDevtools />
        </QueryClientProvider>
      </TrucksProvider>
    </AuthProvider>
  </React.StrictMode>
);
