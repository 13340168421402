import { useNavigate, useSearchParams } from 'react-router-dom';
import { tableHeader } from '../../data/ordersTable';
import { FunnelIcon } from '@heroicons/react/24/outline';
import { Pagination } from '../Pagination/Pagination';
import { SortBy } from '../Search/SortBy';
import cn from 'classnames';
import { useFetchAllPayers } from '../../hooks/useUsers';

export const Clients = () => {
  const [ searchParams, setSearchParams ] = useSearchParams();
  const navigate = useNavigate();

  const { clients, count } = useFetchAllPayers();
  // useEffect(() => {
  //   const params = searchParams.toString();

  //   if (params.length > 0) {
  //     setQuery(params);
  //   } else {
  //     setQuery('');
  //   }
   
  // }, [ searchParams ]);

  const handleChooseOrder = async ( orderNo: string | undefined ) => {

    if (!orderNo) {
      return;
    }

    navigate(`/orders/${orderNo}`);

  };

  if (!clients) {
    return null;
  }
  
  return (
    <div className='px-4 sm:px-6 lg:px-8'>
      <div className='sm:flex sm:items-center'>
        <div className='sm:flex-auto'>
          <h1 className='text-base font-semibold leading-6 text-gray-900'>
            Clients
          </h1>
          <p className='mt-2 text-sm text-gray-700'>
            A list of all your clients.
          </p>
        </div>
        <form className='relative flex flex-1' action='#' method='GET'>
          <label htmlFor='filter-field'
            className='sr-only'
            // className="block text-sm font-medium leading-6 text-gray-900"
          >
                  Filter
          </label>
          <FunnelIcon
            className='pointer-events-none absolute inset-y-0 left-3 h-full w-5 text-gray-400'
            aria-hidden='true'
          />
          <input
            id='filter-field'
            // className='block h-full w-full border-0 py-0 pl-8 pr-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm'
            className="block w-full px-10 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset hover:ring-orange-500 focus:ring-orange-600 sm:text-sm sm:leading-6"
            placeholder='Filter...'
            type='text'
            name='filter'
            value={searchParams.get('query') || ''}
            onChange={(e) => {
              searchParams.set('query', `${e.target.value}`);
  
              if (searchParams.get('query') === '') {
                searchParams.delete('query');
              }
  
              setSearchParams(searchParams);
            }}
          />
         
        </form>

        <div className='mt-4 sm:ml-16 sm:mt-0 sm:flex-none'>
            
          <button
            type='button'
            onClick={() => navigate('/new-order')}
            className='block rounded-md bg-orange-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600'
          >
            Add client
          </button>
        </div>
      </div>
      <div className='mt-8 flow-root'>
        <div className='-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8'>
          <div className='inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8'>
            <table className='min-w-full divide-y divide-gray-300'>
              <thead>
                <tr>
                  {Object.entries(tableHeader).map(([ column, sort ], index) => (
                    <th
                      key={column}
                      scope='col'
                      className={cn('pr-3 py-3.5 text-left text-sm font-semibold text-gray-900', {
                        'px-3': index !== 0
                      })}
                    >
                      <SortBy  sort={sort} label={column} />
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className='divide-y divide-gray-200 bg-white'>
                {clients && clients.map((client) => (
                  <tr key={client.id} className='hover:bg-orange-600/20 cursor-pointer' onClick={() => handleChooseOrder(client.id.toString())}>
                    <td className='whitespace-nowrap py-4 pl-4 px-3 text-sm  font-medium text-gray-900 sm:pl-0'>
                      {client.firstName}
                    </td>
                    <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                      {client.lastName}
                    </td>
                    <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                      {client.companyName}
                    </td>
                    <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                      {client.street}
                    </td>
              
              
                    <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                      {client.eori}
                    </td>
                    <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                      {client.email}
                    </td>
                    {/* <td className='relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm sm:pr-0'>
                      <a
                        href='#'
                        className='text-orange-600 hover:text-orange-900'
                      >
                        Edit<span className='sr-only'></span>
                      </a>
                    </td> */}
                  </tr>
                ))}
              </tbody>
              
            </table>
            
          </div>
          
        </div>
      </div>
      {Math.ceil( count / Number(searchParams.get('perPage'))) > 1 && <Pagination totalItems={count} />}  
      {/* {Math.ceil(ordersCount / perPage) > 1 && <Pagination page={page} perPage={perPage} totalItems={ordersCount} handleSetPage={handleSetPage} />}   */}
    </div>
  );
};
