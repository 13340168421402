import axios from 'axios';

const baseUrl = process.env.REACT_APP_WEBFLEET_REQUEST_URL;

export const getCanCodes = async (data: string) => {
  console.log(baseUrl);
  const response = await axios.get(baseUrl + 'getObjectCanSignals&objectno=' + data);
  return response;
};

export const getCanMalfunctionCodes = async (data: string) => {
  console.log(baseUrl);
  const response = await axios.get(baseUrl + 'getObjectCanMalfunctions&objectno=' + data);
  return response;
};