import { AtSymbolIcon, EnvelopeIcon, PencilSquareIcon, PhoneIcon, UserCircleIcon } from '@heroicons/react/24/outline';
import {  useParams } from 'react-router-dom';
import { useAuthContext } from '../../context/AuthProvider';
import cn from 'classnames';
import { useDeleteOrder, useFetchOrder } from '../../hooks/useOrders';
import { useNavigate } from 'react-router-dom';
const tableHeader = [ 'No.', 'Goods', 'Width[cm]', 'Length[cm]', 'Height[cm]', 'Weight[kg]', 'QTY', 'Total weight[kg]' ];


export const OrderDetails = () => {
  const { activeUser } = useAuthContext();
  const { orderNo } = useParams();
  const { deleteOrder } = useDeleteOrder();
  const navigate = useNavigate();
  
  if (!orderNo) {
    return null;
  }

  const { order } = useFetchOrder(+orderNo);  

  if (!order) {
    return null;
  }

  const handleDeleteOrder = async () => {
    if (order.id) {
      await deleteOrder(+order.id);
      navigate('/orders');
    }

  };
  return (
    // <div className="divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow">
    <>
      <div className='sm:flex sm:items-center'>

 
        <div className='flex-auto'>
          <div className="px-4 py-5 sm:px-6">
        Order no.: <strong>{order.orderNo}</strong>
          </div>

          {(activeUser?.role === 'admin' || activeUser?.role === 'logistics') &&
    <div className="px-4 sm:px-6 text-sm py-2">
    Orderer: <span className="mt-1 text-sm leading-6 text-gray-700  sm:mt-0">{order.orderer?.firstName} {order.orderer?.lastName} {order.orderer?.companyName && `| ${order.orderer.companyName}`}
      </span>
    </div> }
  
          <div className="px-4 sm:px-6 text-sm py-2">
          Order status: <dd className="inline-flex ml-4 mb-3 items-center rounded-md bg-orange-50 px-2 py-2 text-xs font-medium text-orange-700 ring-1 ring-inset ring-orange-600/20">
              {order.status} 
            </dd>

            {order.paid && 
        <dd className="inline-flex ml-4 mb-3 items-center rounded-md bg-green-50 px-2 py-2 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
              Paid
        </dd>}
            {!order.paid && 
            <>
 
              <dd className="inline-flex ml-4 mb-3 items-center rounded-md bg-red-50 px-2 py-2 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/20">
              Unpaid
              </dd>
              <button
                type='button'
                onClick={() => navigate(`/checkout/${orderNo}`)}
                className='mx-5 rounded-md bg-orange-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600'
              >
               Pay
              </button>
                   
            </>}
          </div>

          <div className="px-4 sm:px-6 text-sm py-2">
          Order weight / area: <span className="mt-1 text-sm leading-6 text-gray-700  sm:mt-0">{order.weight}kg / {order.floorArea / 10000 + 'm\xB2'}
            </span>
          </div>
          <div className="px-4 sm:px-6 text-sm py-2">
          Price: <span className="mt-1 text-sm leading-6 text-gray-700  sm:mt-0">{order.price && order.vat === '23%' ? (order.price * 1.23).toFixed(2)   : order.price} {order.currency.currencySymbol} 
            </span>
          </div>
        </div>
 
        
        <div className='mt-4 sm:ml-16 sm:mt-0 sm:flex-none mx-4 '>
          <button
            type='button'
            onClick={() => navigate('/orders')}
            className='block rounded-md bg-orange-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600'
          >
               Back
          </button>
          {!order.paid && 
          <button
            type='button'
            onClick={handleDeleteOrder}
            className='mt-8 block rounded-md bg-orange-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600'
          >
               Delete order
          </button>
          }
        </div>
      
   
      </div>
      <div className="px-4 py-5 sm:p-6 flex   gap-5 flex-wrap mt-6 border-t border-gray-100">

        <div className='min-w-32 flex-grow '>
          <div className="px-4 sm:px-0">
       
            <h3 className="text-base font-semibold leading-7 text-gray-900">Sender</h3>
            {/* <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">Personal details and application.</p> */}
          </div>
          <div className="mt-6 border-t border-gray-100">
            <dl className="divide-y divide-gray-100">
              <div className="px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-1 sm:px-0">
                {/* <dt className="text-sm font-medium leading-6 text-gray-900">Full name</dt> */}
                <UserCircleIcon 
                  className='h-6 w-6 shrink-0 text-sm font-medium leading-6 text-orange-600'
                  aria-hidden='true' />
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{order.sender?.name} {order.sender?.surname}{order.sender?.company && ` | ${order.sender.company}`}</dd>
              </div>
              <div className="px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-1 sm:px-0">
                {/* <dt className="text-sm font-medium leading-6 text-gray-900">Application for</dt>
                 */}
                <EnvelopeIcon 
                  className='h-6 w-6 shrink-0 text-sm font-medium leading-6 text-orange-600'
                  aria-hidden='true' />
                <dd className="hover:text-orange-600 mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 col-start-2"><a target="_blank" rel="noopener noreferrer" href={`https://www.google.com/maps/place/${order.sender?.coordinates?.lat}+${order.sender?.coordinates?.lon}` }>{order.sender?.street} {order.sender?.buildingNo}{order.sender?.apartmentNo && `, Apt: ${order.sender.apartmentNo}`}<br></br>
                  {order.sender?.postCode} {order.sender?.city}, {order.sender?.country}</a></dd>
              </div>
              <div className="px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-1 sm:px-0">
                {/* <dt className="text-sm font-medium leading-6 text-gray-900">Email address</dt> */}
                <AtSymbolIcon 
                  className='h-6 w-6 shrink-0 text-sm font-medium leading-6 text-orange-600'
                  aria-hidden='true' />
                <dd className="hover:text-orange-600 mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0"><a href={`mailto:${order.sender?.email}`}>{order.sender?.email}</a></dd>
              </div>
              <div className="px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-1 sm:px-0">
                {/* <dt className="text-sm font-medium leading-6 text-gray-900">Email address</dt> */}
                <PhoneIcon 
                  className='h-6 w-6 shrink-0 text-sm font-medium leading-6 text-orange-600'
                  aria-hidden='true' />
                <dd className="hover:text-orange-600 mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0"><a href={`callto:${order.sender?.phone}`}>{order.sender?.phone}</a></dd>
              </div>
              {order.sender?.notes && 
                <div className="px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-1 sm:px-0">
                  {/* <dt className="text-sm font-medium leading-6 text-gray-900">Email address</dt> */}
                  <PencilSquareIcon 
                    className='h-6 w-6 shrink-0 text-sm font-medium leading-6 text-orange-600'
                    aria-hidden='true' />
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{order.sender?.notes}</dd>
                </div>
              }
  
        
            </dl>
          </div>
        </div>

        <div className='min-w-32 flex-grow'>
          <div className="px-4 sm:px-0">
       
            <h3 className="text-base font-semibold leading-7 text-gray-900">Receiver</h3>
            {/* <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">Personal details and application.</p> */}
          </div>
          <div className="mt-6 border-t border-gray-100">
            <dl className="divide-y divide-gray-100">
              <div className="px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-1 sm:px-0">
                {/* <dt className="text-sm font-medium leading-6 text-gray-900">Full name</dt> */}
                <UserCircleIcon 
                  className='h-6 w-6 shrink-0 text-sm font-medium leading-6 text-orange-600'
                  aria-hidden='true' />
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{order.receiver?.name} {order.receiver?.surname}{order.receiver?.company && ` | ${order.receiver.company}`}</dd>
              </div>
              <div className="px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-1 sm:px-0">
                {/* <dt className="text-sm font-medium leading-6 text-gray-900">Application for</dt>
                 */}
                <EnvelopeIcon 
                  className='h-6 w-6 shrink-0 text-sm font-medium leading-6 text-orange-600'
                  aria-hidden='true' />
                <dd className="hover:text-orange-600 mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 col-start-2"><a target="_blank" rel="noopener noreferrer" href={`https://www.google.com/maps/place/${order.receiver?.coordinates?.lat}+${order.receiver?.coordinates?.lon}` }>{order.receiver?.street} {order.receiver?.buildingNo}{order.receiver?.apartmentNo && `, Apt: ${order.receiver.apartmentNo}`}<br></br>
                  {order.receiver?.postCode} {order.receiver?.city}, {order.receiver?.country}</a></dd>
              </div>
              <div className="px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-1 sm:px-0">
                {/* <dt className="text-sm font-medium leading-6 text-gray-900">Email address</dt> */}
                <AtSymbolIcon 
                  className='h-6 w-6 shrink-0 text-sm font-medium leading-6 text-orange-600'
                  aria-hidden='true' />
                <dd className="hover:text-orange-600 mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0"><a href={`mailto:${order.receiver?.email}`}>{order.receiver?.email}</a></dd>
              </div>
              <div className="px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-1 sm:px-0">
                {/* <dt className="text-sm font-medium leading-6 text-gray-900">Email address</dt> */}
                <PhoneIcon 
                  className='h-6 w-6 shrink-0 text-sm font-medium leading-6 text-orange-600'
                  aria-hidden='true' />
                <dd className="hover:text-orange-600 mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0"><a href={`callto:${order.receiver?.phone}`}>{order.receiver?.phone}</a></dd>
              </div>
              {order.receiver?.notes && 
                <div className="px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-1 sm:px-0">
                  {/* <dt className="text-sm font-medium leading-6 text-gray-900">Email address</dt> */}
                  <PencilSquareIcon 
                    className='h-6 w-6 shrink-0 text-sm font-medium leading-6 text-orange-600'
                    aria-hidden='true' />
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{order.receiver?.notes}</dd>
                </div>
              }
  
        
            </dl>
          </div>
        </div>

        <div className='min-w-32 mt-6 border-t col-span-2 border-gray-100 overflow-auto flex-grow'>
          <div className="px-4 sm:px-0">
       
            <h3 className="text-base font-semibold mt-4 leading-7 text-gray-900">Packages</h3>
            {/* <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">Personal details and application.</p> */}
          </div>
          <div className="px-4 py-3 sm:grid sm:grid-cols-1 sm:gap-1 sm:px-0">
            {/* <dt className="text-sm font-medium leading-6 text-gray-900">Email address</dt> */}
          
            <table className='min-w-full divide-y divide-gray-300'>
              <thead>
                <tr>
                  {tableHeader.map(head => (
                    <th
                      key={head}
                      scope='col'
                      className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>{head}</th>)
                  )}
                </tr>
              </thead>
              <tbody>
             
                {order.packages.map((pack, index) => (
                  <tr
                    key={pack.id}
                    className={cn('px-3 py-3.5 text-left text-sm text-gray-900', {
                      'cursor-pointer hover:bg-orange-600/20': pack.link
                    })}
                    onClick={() => {
                      if (pack.link) {
                        window.open(`https://${pack.link}`, '_blank', 'noopener,noreferrer');
                      }
                    }
                     
                    }
                  >
                   
                    <td className='px-3 py-2 text-left text-sm text-gray-900'>
                      {index + 1}
                    </td>
                    <td className='px-3 py-2 text-left text-sm text-gray-900'>
                      {pack.goods}
                    </td>
                    <td className='px-3 py-3.5 text-left text-sm text-gray-900'>
                      {pack.width}
                    </td>
                    <td className='px-3 py-3.5 text-left text-sm text-gray-900'>
                      {pack.length}
                    </td>
                    <td className='px-3 py-3.5 text-left text-sm text-gray-900'>
                      {pack.height}
                    </td>
                    <td className='px-3 py-3.5 text-left text-sm text-gray-900'>
                      {pack.weight}
                    </td>
                    <td className='px-3 py-3.5 text-left text-sm text-gray-900'>
                      {pack.pcs}
                    </td>
                    <td className='px-3 py-3.5 text-left text-sm text-gray-900'>
                      {Number(pack.weight) * pack.pcs}
                    </td>
               
                  </tr>
                       
                ))}
                
              </tbody>
               

            </table>
     
       
     
            
          </div>
        </div>
      </div>
    </>
    // {/* </div> */}
  );
};


