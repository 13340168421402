import { useEffect, useState } from 'react';
import { useAuthContext } from '../context/AuthProvider';
import { Vehicle } from '../types/Vehicle';
import { getVan, getVans } from '../api/vans';

export const useTrucks = () => {
  const [ trucks, setTrucks ] = useState<Vehicle[]>([]);
  const [ trucksCount, setTrucksCount ] = useState<number>(0);
  const [ isLoading, setIsLoading ] = useState<boolean>(false);
  const [ truck, setTruck ] = useState<Vehicle | undefined>(undefined);
  const [ query, setQuery ] = useState<string | null>(null);
  const { isAuthChecking, logging, activeUser } = useAuthContext();


  useEffect(() => {
    if (isAuthChecking || logging) {
      return;
    }
    
    const fetchTrucks = async () => {
      try {
        const response =  await getVans(query as string);
        if (!response) {
          return;
        }
  
        const { count, trucks: vans } = response.data;
  
        const vansWithActive = (vans as Vehicle[]).map(van => {
          if (Math.round(((new Date()).getTime() - (new Date(van.pos_time as string)).getTime())/1000/60/60/24) < 30) {
            return {
              ...van,
              active: true
            };
          } else {
            return {
              ...van,
              active: false
            };
          }
        });
  
        setTrucks(vansWithActive);
        setTrucksCount(count);
      } catch (e) {
        console.log('Problem fetching trucks. Error: ', e);
      } finally {
        setIsLoading(false);
      }
    };

    if ((activeUser?.role === 'admin' || activeUser?.role === 'logistics') && !isAuthChecking && !logging && query !== null) {
      
      fetchTrucks();
    }

  }, [ isAuthChecking, logging, query ]);

  const downloadTruck = async (truckId: string) => {
    console.log('download truck');
    try {
      const response = await getVan(truckId);
      console.log(response);
      if (!response) {
        return;
      }
      setTruck(response.data);
    } catch (e) {
      console.log('Problem downloading truck: ', e);
    }
  };


  const updateTruck = async (data: Vehicle) => {
    console.log(data);
  };
  return {
    updateTruck,
    setTruck,
    downloadTruck,
    trucks,
    isLoading,
    trucksCount,
    truck,
    setQuery
  };
};