import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useAuthContext } from '../../context/AuthProvider';
import { ExclamationTriangleIcon, XCircleIcon } from '@heroicons/react/24/outline';
// import { User } from '../../types/User';
import { useNavigate } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form';

export const Login = () => {
  const { attempts, logging, error, userLogin, activeUser, setActiveUser } = useAuthContext();
  // const [ user, setUser ] = useState<Partial <User>>({ email: '', password: '' });
  const  { register, handleSubmit, }  = useForm<LoginForm>();

  const navigate = useNavigate();

  const BASE_URL = process.env.REACT_APP_STATIC_URL;

  interface LoginForm {
    email: string,
    password: string
  }

  useEffect(() => {

    if (activeUser) {
      navigate('/');
    }

  }, [ activeUser ]);


  const onSubmit: SubmitHandler<LoginForm> = async (data) => {
    try {
      const loggedUser = await userLogin(data);

      if (typeof loggedUser === 'object') {
        setActiveUser(loggedUser);
      }
      
      navigate('/');
    } catch (error) {
      console.log(error);
    }
    
  };
  

  return (
    <>
      <div className='flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8'>
        <div className='sm:mx-auto sm:w-full sm:max-w-sm'>
          <img
            className='mx-auto h-24 w-auto'
            src={BASE_URL + '/ve_logo.png'}
            alt='VE logo'
          />
          <h2 className='mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900'>
          Sign in to your account
          </h2>
        </div>

        <div className='mt-10 sm:mx-auto sm:w-full sm:max-w-sm'>
          <form onSubmit={handleSubmit(onSubmit)} className='space-y-6' action='#' method='POST'>
            <div>
              <label
                htmlFor='email'
                className='block text-sm font-medium leading-6 text-gray-900'
              >
                Email address
              </label>
              <div className='mt-2'>
                <input
                  id='email'
                  // name='email'
                  type='email'
                  autoComplete='email'
                  required
                  // value={user.email}
                  {...register('email', { required: true })}
                  // onChange={e => setUser(prev => {
                  //   return {
                  //     ...prev,
                  //     email: e.target.value
                  //   };})
                  // }
                  className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-orange-600 sm:text-sm sm:leading-6'
                />
              </div>
            </div>

            <div>
              <div className='flex items-center justify-between'>
                <label
                  htmlFor='password'
                  className='block text-sm font-medium leading-6 text-gray-900'
                >
                  Password
                </label>
                <div className='text-sm'>
                  <Link
                    to={'/passwordReset'}
                    className='font-semibold text-orange-600 hover:text-orange-500'
                  >
                    Forgot password?
                  </Link>
                </div>
              </div>
              <div className='mt-2'>
                <input
                  id='password'
                  // name='password'
                  type='password'
                  autoComplete='current-password'
                  required
                  // value={user.password}
                  {...register('password', { required: true })}
                  // onChange={e => setUser(prev => {
                  //   return {
                  //     ...prev,
                  //     password: e.target.value
                  //   };})
                  // }
                  className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-orange-600 sm:text-sm sm:leading-6'
                />
              </div>
            </div>

            <div>
              <button
                type='submit'
                className='flex w-full justify-center rounded-md bg-orange-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600'
              >
                Sign in
              </button>
            </div>
          </form>

          <p className='mt-10 text-center text-sm text-gray-500'>
            Not a member?{' '}
            <Link
              to='/registration'
              className='font-semibold leading-6 text-orange-600 hover:text-orange-500'
            >
              Sign up!
            </Link>
          </p>
          {error && <>
            <div className="rounded-md bg-red-50 p-4">
              <div className="flex">
                <div className="flex-shrink-0">
                  <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                </div>
                <div className="ml-3">
                  <h3 className="text-sm font-medium text-red-800">User or password incorrect or user not not activated</h3>
                </div>
              </div>
            </div>

            <p></p>
          </>  }

          {attempts && <>
            <div className="rounded-md bg-red-50 p-4">
              <div className="flex">
                <div className="flex-shrink-0">
                  <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                </div>
                <div className="ml-3">
                  <h3 className="text-sm font-medium text-red-800">To many login attempts. Wait 15 minutes and try again</h3>
                </div>
              </div>
            </div>

            <p></p>
          </>  }
          {logging && <>

            <div className="rounded-md bg-yellow-50 p-4">
              <div className="flex">
                <div className="flex-shrink-0">
                  <ExclamationTriangleIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                </div>
                <div className="ml-3">
                  <h3 className="text-sm font-medium text-yellow-800">Logging in...</h3>
         
                </div>
              </div>
            </div>


            <p></p>
          </>  }
         
        </div>
      </div>
    </>
  );
};
