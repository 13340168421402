import { useState } from 'react';
import { PasswordLink } from './PasswordSet/PasswordLink';
import { client } from '../../services/axiosClient';
import { SubmitHandler, useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';

export const PasswordReset = () => {

  type FormData = {
    email: string
  }
  const { register, handleSubmit, formState: { errors } } = useForm<FormData>();
  // const [ user, setUser ] = useState({ email: '' });
  const [ passwordLink, setPasswordLink ] = useState<boolean>(false);
  const [ isLoading, setIsLoading ] = useState(false);
  
  const BASE_URL = process.env.REACT_APP_STATIC_URL;
  
  const onSubmit: SubmitHandler<FormData> = async (data) => {
    setIsLoading(true);
    try {
      await client.post('/newpass', data);
    
      setPasswordLink(true);
    } catch (error) {
      console.error('Error: Server error');
 
    } finally {
      setIsLoading(false);
    }
  };
  
  return (
    <>
      <div className='flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8'>
        <div className='sm:mx-auto sm:w-full sm:max-w-sm'>
          <img
            className='mx-auto h-24 w-auto'
            src={BASE_URL + '/ve_logo.png'}
            alt='VE logo'
          />
          <h2 className='mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900'>
              Please enter your email to reset your password
          </h2>
        </div>
  
        <div className='mt-10 sm:mx-auto sm:w-full sm:max-w-sm'>
          <form onSubmit={handleSubmit(onSubmit)} className='space-y-6' action='#' method='POST'>
            <div>
              <label
                htmlFor='email'
                className='block text-sm font-medium leading-6 text-gray-900'
              >
                  Email address
              </label>
              <div className='mt-2'>
                <input
                  id='email'
                  type='email'
                  autoComplete='email'
                  {...register('email',  {
                    required: 'Email is required', 
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: 'Invalid email address'
                    }  })}
                  className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-orange-600 sm:text-sm sm:leading-6'
                />
              </div>
              <ErrorMessage
                errors={errors}
                name='email'
                render={({ message }) => <p className='text-red-700 font-medium text-xs inline-flex items-center'>{message}</p>}
              />
            </div>
                    
                    
            <div>
              <button
                type='submit'
                className='flex w-full justify-center rounded-md bg-orange-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600'
              >
                {!isLoading ? 'Reset password' : 'resetting...'}
              </button>
            </div>
          </form>
  
           
        </div>
        <div className='mt-5'>
          {passwordLink && <PasswordLink />}
        </div>
         
      </div>
    </>
  );
};