import { apiClient } from '../services/axiosClient';
import { Country } from '../types/Countries';

const getCountries = async ():Promise<Country[]> => {
  try {
    const { data } = await apiClient.get('/country');
    return data;
  } catch (e) {
    // console.log(e);
    throw new Error('Problem downloading countries');
  }
  
};

export const countriesService = {
  getCountries
};