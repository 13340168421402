import { useCallback, useEffect, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout
} from '@stripe/react-stripe-js';
import { Navigate, useParams } from 'react-router-dom';
import { client } from '../../services/axiosClient';
import { PaymentSuccess } from './PaymentSuccess';

const baseUrl = process.env.REACT_APP_STATIC_URL;
const stripePublic = process.env.REACT_APP_STRIPE_PUBLIC;

if (!stripePublic) {
  throw new Error('Stripe public key missing');
}
// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
// This is your test secret API key.
const stripePromise = loadStripe(stripePublic);

export const CheckoutForm = () => {
  const { orderNo } = useParams();

  if (!orderNo) {
    return null;
  }
  
  const data = {
    orderNo
  };
  console.log('data: ', data);
  const fetchClientSecret = useCallback(() => {
    return client.post(`${baseUrl}/payment/create-checkout-session`, data).then(res => res.data).then(data => data.clientSecret);
  }, []);

  const options = { fetchClientSecret };
  console.log('options: ', options);
  return (
    <div id="checkout">
      <EmbeddedCheckoutProvider
        stripe={stripePromise}
        options={options}
      >
        <EmbeddedCheckout />
      </EmbeddedCheckoutProvider>
    </div>
  );
};

export const Return = () => {
  const [ status, setStatus ] = useState(null);
  // const [ customerEmail, setCustomerEmail ] = useState('');

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const sessionId = urlParams.get('session_id');

    fetch(`${baseUrl}/payment/session-status?session_id=${sessionId}`)
      .then((res) => res.json())
      .then((data) => {
        setStatus(data.status);
        // setCustomerEmail(data.customer_email);
      });
  }, []);

  if (status === 'open') {
    return (
      <Navigate to="/checkout" />
    );
  }

  if (status === 'complete') {
    console.log('complete');

    // <Navigate to='/success'/>;
    return (
      <PaymentSuccess/>
    );
   
  }

  return null;
};