import { MutationCache, QueryCache, QueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';

const createTitle = (errorMsg: string, actionType: 'query' | 'mutation') => {
  const action = actionType === 'query' ? 'fetch' : 'update';
  const title = `Could not ${action} data: ${
    errorMsg ?? 'error connecting to server'
  }`;
  return title;
};

function errorHandler(title: string) {
  
  const id = 'react-query-toast';


  const notify = () => toast(title, {
    toastId: id
  });

  return notify();
}

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
      gcTime: Infinity,
      refetchOnWindowFocus: false,
    },
  },
  queryCache: new QueryCache({
    onError: (error) => {
      const title = createTitle(error.message, 'query');
      errorHandler(title);
    },
  }),

  mutationCache: new MutationCache({
    onError: (error) => {
      const title = createTitle(error.message, 'mutation');
      errorHandler(title);
    },
  }),
});