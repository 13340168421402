/* eslint-disable react/prop-types */
import { useSearchParams } from 'react-router-dom';
import { SearchLink } from './SearchLink';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/20/solid';

type SortByProps = {
  sort: string;
  label: string;
};

export const SortBy: React.FC<SortByProps> = ({ sort, label }) => {
  const [ searchParams ] = useSearchParams();

  type Params = {
    sort: string | null
    order: string | null
  };

  let params: Params = {
    sort: null,
    order: null,
  };

  if (searchParams.get('sort') !== sort) {
    params = {
      ...params,
      sort,
    };
  } else if (searchParams.get('sort') === sort
  && searchParams.get('order') === null) {
    params = {
      sort,
      order: 'desc',
    };
  }

  return (
    <SearchLink params={params}>
 
      <div className='group inline-flex' >
        {label}
        {searchParams.get('sort') !== sort &&
          <span className='invisible ml-2 flex-none rounded text-gray-400 group-hover:visible group-focus:visible'>
            <ChevronDownIcon
              className='h-5 w-5'
              aria-hidden='true'
            />
          </span>
        }
       
        {(searchParams.get('sort') === sort
          && searchParams.get('order')) && 
          <span className='ml-2 flex-none rounded bg-gray-100 text-gray-900 group-hover:bg-gray-200'>
            <ChevronDownIcon
              className='h-5 w-5'
              aria-hidden='true'
            />
          </span>}
        {(searchParams.get('sort') === sort
          && !searchParams.get('order')) && 
          <span className='ml-2 flex-none rounded bg-gray-100 text-gray-900 group-hover:bg-gray-200'>
            <ChevronUpIcon
              className='h-5 w-5'
              aria-hidden='true'
            />
          </span>}
       
      </div>
   
    </SearchLink>
  );
};
