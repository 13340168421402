import { useEffect, useState } from 'react';

import { TomTomResult } from '../../../types/TomTomData';
import { AutoFill } from '../../AutoFillForm/AutoFill';
import { useFormContext } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { useGetCountries } from '../../../hooks/useCountries';
import { Loader } from '../../Loader/Loader';



export const SenderContact = () => {
  const [ senderAddress, setSenderAddress ] = useState<TomTomResult | null>(null);

  const { countries, isLoading } = useGetCountries();
  const { register, watch, setValue, formState: { errors } } = useFormContext();

  const countryCode = countries?.find(co => co.countryName === watch('sender.country'))?.countryCode;
  
  useEffect(() => {
    if (senderAddress !== null) {
      setValue('sender.street', senderAddress.address?.streetName || '');
      setValue('sender.buildingNo', senderAddress.address?.streetNumber || '');
      setValue('sender.city', senderAddress.address?.municipality || '');
      setValue('sender.postCode', senderAddress.address?.postalCode || '');
      setValue('sender.country', senderAddress.address?.country || '');
      setValue('sender.state', senderAddress.address?.countrySubdivision || '');
      setValue('sender.countryCode', senderAddress.address?.countryCode || '');
      setValue('sender.coordinates.lat', senderAddress.position?.lat || '');
      setValue('sender.coordinates.lon', senderAddress.position?.lon || '');
    } else {
      setValue('sender.street', '');
      setValue('sender.buildingNo', '');
      setValue('sender.city', '');
      setValue('sender.postCode', '');
      setValue('sender.state', '');
      setValue('sender.countryCode', '');
      setValue('sender.coordinates.lat', '');
      setValue('sender.coordinates.lon', '');
    }
  }, [ senderAddress, setValue ]);
  if (isLoading) {
    return <Loader/>;
  }
  return (
    <div className='grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-5'>
      <div>
        <h2 className='text-base font-semibold leading-7 text-gray-900'>
        Sender
        </h2>

        <p className='mt-1 text-sm leading-6 text-gray-600'>
        Name and address of the sender.
        </p>
      </div>

      <div className='grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-4'>
   
        <div className='sm:col-span-3'>
          <label
            htmlFor='sender-first-name'
            className='block text-sm font-medium leading-6 text-gray-900'
          >
          First name
          </label>

          <div className='mt-2'>
            <input
              type='text'
              id='sender-first-name'
              autoComplete='given-name'
              { ...register('sender.name', { required: 'First name is required' })}
              className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset  hover:ring-orange-500 focus:ring-orange-600 sm:text-sm sm:leading-6'
            />
            <ErrorMessage
              errors={errors}
              name='sender.name'
              render={({ message }) => <p className='text-red-700 font-medium text-xs inline-flex items-center'>{message}</p>}
            />
          </div>
        </div>

        <div className='sm:col-span-3'>
          <label
            htmlFor='sender-last-name'
            className='block text-sm font-medium leading-6 text-gray-900'
          >
          Last name
          </label>

          <div className='mt-2'>
            <input
              type='text'
              id='sender-last-name'
              autoComplete='family-name'
              {...register('sender.surname', { required: 'Sender surname is required' })}
              className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset hover:ring-orange-500 focus:ring-orange-600 sm:text-sm sm:leading-6'
            />
            <ErrorMessage
              errors={errors}
              name='sender.surname'
              render={({ message }) => <p className='text-red-700 font-medium text-xs inline-flex items-center'>{message}</p>}
            />
          </div>
        </div>

        <div className='sm:col-span-4'>
          <label
            htmlFor='sender-company'
            className='block text-sm font-medium leading-6 text-gray-900'
          >
          Company
          </label>

          <div className='mt-2'>
            <input
              id='sender-company'
              type='text'
              autoComplete='organization'
              {...register('sender.company')}
              className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset hover:ring-orange-500 focus:ring-orange-600 sm:text-sm sm:leading-6'
            />
 
          </div>
        </div>

        <div className='sm:col-span-3'>
          <label
            htmlFor='sender-email'
            className='block text-sm font-medium leading-6 text-gray-900'
          >
          Email address
          </label>
          <div className='mt-2'>
            <input
              id='sender-email'
              type='email'
              autoComplete='email'
              {...register('sender.email', {
                required: 'e-mail is reqired',
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: 'Invalid email address'
                }  
              })}
              className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset hover:ring-orange-500 focus:ring-orange-600 sm:text-sm sm:leading-6'
            />
            <ErrorMessage
              errors={errors}
              name='sender.email'
              render={({ message }) => <p className='text-red-700 font-medium text-xs inline-flex items-center'>{message}</p>}
            />
          </div>
        </div>

        <div className='sm:col-span-3'>
          <label
            htmlFor='sender-phone'
            className='block text-sm font-medium leading-6 text-gray-900'
          >
          Phone no.
          </label>

          <div className='mt-2'>
            <input
              id='sender-phone'
              type='tel'
              autoComplete='tel'
              {...register('sender.phone', { required: 'Phone no. is required' })}
              className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset hover:ring-orange-500 focus:ring-orange-600 sm:text-sm sm:leading-6'
            />
            <ErrorMessage
              errors={errors}
              name='sender.phone'
              render={({ message }) => <p className='text-red-700 font-medium text-xs inline-flex items-center'>{message}</p>}
            />
          </div>
        </div>

        <div className='sm:col-span-3'>
          <label
            htmlFor='sender-country'
            className='block text-sm font-medium leading-6 text-gray-900'
          >
          Country
          </label>

          <div className='mt-2'>
            <select
              id='sender-country'
              defaultValue={''}
              autoComplete='country-name'
              {...register('sender.country', { required: 'Country is required' })}
              className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset hover:ring-orange-500 focus:ring-orange-600 sm:max-w-xs sm:text-sm sm:leading-6'
            >
              <option value={''} disabled >-- Choose country --</option>
              {countries && countries.filter(coun => coun.isEnabled).filter(value => watch('receiver.country') !== 'Poland' ? watch('receiver.country') !== value.countryName : value.countryName).map(country => (
                <option key={country.id}>{country.countryName}</option>
              ))}
            </select>
            <ErrorMessage
              errors={errors}
              name='sender.country'
              render={({ message }) => <p className='text-red-700 font-medium text-xs inline-flex items-center'>{message}</p>}
            />
          </div>
        </div>
        {watch('sender.country') && 
<>

  <div className='sm:col-span-full'>
    <AutoFill setChosenData={setSenderAddress} country={countryCode} />
  </div>

  <div className='sm:col-span-4'>
    <label
      htmlFor='sender-street-address'
      className='block text-sm font-medium leading-6 text-gray-900'
    >
          Street address
    </label>

    <div className='mt-2'>
      <input
        type='text'
        required
        id='sender-street-address'
        autoComplete='address-line1'
        disabled
        {...register('sender.street', { required: 'Street is required' })}
        className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset hover:ring-orange-500 focus:ring-orange-600 sm:text-sm sm:leading-6 cursor-not-allowed'
      />
      <ErrorMessage
        errors={errors}
        name='sender.street'
        render={({ message }) => <p className='text-red-700 font-medium text-xs inline-flex items-center'>{message}</p>}
      />
    </div>
  </div>

  <div className='sm:col-span-1'>
    <label
      htmlFor='sender-building-address'
      className='block text-sm font-medium leading-6 text-gray-900'
    >
          Bldg. No.
    </label>

    <div className='mt-2'>
      <input
        type='text'
        required
        id='sender-building-address'
        autoComplete='address-line2'
        disabled
        {...register('sender.buildingNo', { required: 'Building no. is required' })}
        className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset hover:ring-orange-500 focus:ring-orange-600 sm:text-sm sm:leading-6 cursor-not-allowed'
      />
      <ErrorMessage
        errors={errors}
        name='sender.buildingNo'
        render={({ message }) => <p className='text-red-700 font-medium text-xs inline-flex items-center'>{message}</p>}
      />
    </div>
  </div>

  <div className='sm:col-span-1'>
    <label
      htmlFor='sender-apt-address'
      className='block text-sm font-medium leading-6 text-gray-900'
    >
          Apt
    </label>

    <div className='mt-2'>
      <input
        type='text'
        id='sender-apt-address'
        autoComplete='address-line3'
        {...register('sender.apartmentNo')}
        className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset hover:ring-orange-500 focus:ring-orange-600 sm:text-sm sm:leading-6'
      />
    </div>
  </div>

  <div className='sm:col-span-2 sm:col-start-1'>
    <label
      htmlFor='sender-city'
      className='block text-sm font-medium leading-6 text-gray-900'
    >
          City
    </label>

    <div className='mt-2'>
      <input
        type='text'
        id='sender-city'
        required
        autoComplete='address-level2'
        disabled
        {...register('sender.city', { required: 'City is required' })}
        className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset hover:ring-orange-500 focus:ring-orange-600 sm:text-sm sm:leading-6 cursor-not-allowed'
      />
      <ErrorMessage
        errors={errors}
        name='sender.city'
        render={({ message }) => <p className='text-red-700 font-medium text-xs inline-flex items-center'>{message}</p>}
      />
    </div>
  </div>

  <div className='sm:col-span-2'>
    <label
      htmlFor='sender-region'
      className='block text-sm font-medium leading-6 text-gray-900'
    >
          State / Province
    </label>

    <div className='mt-2'>
      <input
        type='text'
        id='sender-region'
        autoComplete='address-level1'
        disabled
        {...register('sender.state')}
        className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset hover:ring-orange-500 focus:ring-orange-600 sm:text-sm sm:leading-6 cursor-not-allowed'
      />
    </div>
  </div>

  <div className='sm:col-span-2'>
    <label
      htmlFor='sender-postal-code'
      className='block text-sm font-medium leading-6 text-gray-900'
    >
          ZIP / Postal code
    </label>

    <div className='mt-2'>
      <input
        type='text'
        id='sender-postal-code'
        required
        autoComplete='postal-code'
        disabled
        {...register('sender.postCode', { required: 'Postal code is required' })}
        className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset hover:ring-orange-500 focus:ring-orange-600 sm:text-sm sm:leading-6 cursor-not-allowed'
      />
    </div>
  </div>

  <div className='col-span-full'>
    <label
      htmlFor='sender-notes'
      className='block text-sm font-medium leading-6 text-gray-900'
    >
          Notes
    </label>

    <div className='mt-2'>
      <input
        type='text'
        placeholder='ex.: you have to use the back door'
        id='sender-notes'
        {...register('sender.notes')}
        className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset hover:ring-orange-500 focus:ring-orange-600 sm:text-sm sm:leading-6'
      />
    </div>
  </div>
</>}
      </div>
    </div>
  );
};