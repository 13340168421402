import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
import cn from 'classnames';
import { useSearchParams } from 'react-router-dom';
import { SearchLink } from '../Search/SearchLink';
// import { Selector } from '../Selector/Selector';

type PaginationProps = {
    // perPage: number,
    totalItems: number,
    // handleSetPage: (page: number) => void
}
// { page, perPage, totalItems, handleSetPage }: PaginationProps
export const  Pagination = ({ totalItems }: PaginationProps) => {
  const [ searchParams ] = useSearchParams();
  //   searchParams.set('page', '1');
  //   searchParams.set('perpage', '8');
  console.log('searchparams:', searchParams.get('perPage'));
  const currentPage = searchParams.get('page') || '1';
  const perPage = searchParams.get('perPage') || '8';
  //   const totalPages = Math.ceil(totalItems / perPage);
  console.log('perPage: ', perPage);
  return (
    <div className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6 pt-8">
      <div className="flex flex-1 justify-between sm:hidden">
        <SearchLink params={{ page: (+currentPage > 1 ? +currentPage - 1 : 1).toString() }}
          className={cn(' relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50',{
            'cursor-not-allowed disabled': +currentPage === 1,
            'cursor-pointer': +currentPage !== 1
          })}
          
        >
          Previous
        </SearchLink>
        <SearchLink params={{ page: (+currentPage === Math.ceil(totalItems / +perPage) ? Math.ceil(totalItems / +perPage) : +currentPage + 1).toString() }}
     
          //   onClick={() => handleSetPage(page + 1)}
          className={cn(' relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50',{
            'cursor-not-allowed disabled': currentPage === Math.ceil(totalItems / +perPage).toString(),
            'cursor-pointer': currentPage !== Math.ceil(totalItems / +perPage).toString()
          })}
        >
          Next
        </SearchLink>
      </div>
      <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
        <div>
          <p className="text-sm text-gray-700">
            Showing <span className="font-medium">{(+currentPage - 1) * +perPage + 1}</span> to <span className="font-medium">{+currentPage * +perPage > totalItems ? totalItems : +currentPage * +perPage}</span> of{' '}
            <span className="font-medium">{totalItems}</span> results
          </p>
        </div>
        {/* <Selector /> */}
        <div>
          <nav className="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
            <SearchLink params={{ page: (+currentPage > 1 ? +currentPage - 1 : 1).toString() }}
            //   onClick={() => handleSetPage(page - 1)}
              className={cn('relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0',{
                'cursor-not-allowed disabled': currentPage === '1',
                'cursor-pointer': currentPage !== '1'
              })}
            >
              <span className="sr-only">Previous</span>
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </SearchLink>
            <SearchLink params={{ page: '1' }}
            //   onClick={() => handleSetPage(1)}
            //   onClick={() => searchPara}
              aria-current="page"
              className={cn({
                'cursor-pointer relative z-10 inline-flex items-center bg-orange-600 px-4 py-2 text-sm font-semibold text-white focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600':
                currentPage === '1',
                'cursor-pointer relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0': currentPage !== '1'
              })}
            >
              1
            </SearchLink>
            <SearchLink params={{ page: '2' }}
            //   onClick={() => handleSetPage(2)}
              className={cn({
                'cursor-pointer relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0': Math.ceil(totalItems / +perPage) >= 2 && +currentPage !== 2,
                'cursor-pointer relative z-10 inline-flex items-center bg-orange-600 px-4 py-2 text-sm font-semibold text-white focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600':
                Math.ceil(totalItems / +perPage) >= 2 && +currentPage === 2,
                'hidden': Math.ceil(totalItems / +perPage) < 2
              })}
            >
              2
            </SearchLink>
            <SearchLink params={{ page: '3' }}
            //   onClick={() => handleSetPage(3)}
              className={cn({
                'cursor-pointer relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0': Math.ceil(totalItems / +perPage) >= 2 && +currentPage !== 3,
                'cursor-pointer relative z-10 inline-flex items-center bg-orange-600 px-4 py-2 text-sm font-semibold text-white focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600':
                Math.ceil(totalItems / +perPage) >= 4 && +currentPage === 3,
                'hidden': Math.ceil(totalItems / +perPage) < 4
              })}
            >
              3
            </SearchLink>
            <span className={cn(  'cursor-pointer relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-700 ring-1 ring-inset ring-gray-300 focus:outline-offset-0',{
            
              'hidden': Math.ceil(totalItems / +perPage) <= 6
            })}>
              ...
            </span>
            <SearchLink params={{ page: (Math.ceil(totalItems / (+perPage)) - 2).toString() }}
            //   onClick={() => handleSetPage(Math.ceil(totalItems / (perPage)) - 2)}
              className={cn({
                'cursor-pointer relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0': Math.ceil(totalItems / +perPage) >= 6 && +currentPage !== Math.ceil(totalItems / (+perPage)) - 2,
                'cursor-pointer relative z-10 inline-flex items-center bg-orange-600 px-4 py-2 text-sm font-semibold text-white focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600':
                Math.ceil(totalItems / +perPage) >= 6 && +currentPage === Math.ceil(totalItems / (+perPage)) - 2,
                'hidden': Math.ceil(totalItems / +perPage) < 6
              })}
            >
              {Math.ceil(totalItems / (+perPage)) - 2}
            </SearchLink>
            <SearchLink params={{ page: (Math.ceil(totalItems / (+perPage)) - 1).toString() }}
            //   onClick={() => handleSetPage(Math.ceil(totalItems / (perPage)) - 1)}
              className={cn({
                'cursor-pointer relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0': Math.ceil(totalItems / +perPage) >= 4 && +currentPage !== Math.ceil(totalItems / (+perPage)) - 1,
                'cursor-pointer relative z-10 inline-flex items-center bg-orange-600 px-4 py-2 text-sm font-semibold text-white focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600':
                Math.ceil(totalItems / +perPage) >= 4 && +currentPage === Math.ceil(totalItems / (+perPage)) - 1,
                'hidden': Math.ceil(totalItems / +perPage) <= 4
              })}
            >
              {Math.ceil(totalItems / (+perPage)) - 1}
            </SearchLink>
            <SearchLink params={{ page: (Math.ceil(totalItems / (+perPage))).toString() }}
            //   onClick={() => handleSetPage(Math.ceil(totalItems / (perPage)))}
              className={cn({
                'cursor-pointer relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0': Math.ceil(totalItems / +perPage) >= 2 && +currentPage !== Math.ceil(totalItems / (+perPage)),
                'cursor-pointer relative z-10 inline-flex items-center bg-orange-600 px-4 py-2 text-sm font-semibold text-white focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600':
                Math.ceil(totalItems / +perPage) >= 2 && +currentPage === Math.ceil(totalItems / +perPage),
                'hidden': Math.ceil(totalItems / +perPage) <= 2
              })}
            >
              {Math.ceil(totalItems / +perPage)}
            </SearchLink>
            <SearchLink params={{ page: (+currentPage === Math.ceil(totalItems / +perPage) ? Math.ceil(totalItems / +perPage) : +currentPage + 1).toString() }}
            //   onClick={() => handleSetPage(page + 1)}
              className={cn('relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0',{
                'cursor-not-allowed': currentPage === Math.ceil(totalItems / +perPage).toString(),
                'cursor-pointer': currentPage !== Math.ceil(totalItems / +perPage).toString()
              })}
            >
              <span className="sr-only">Next</span>
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </SearchLink>
          </nav>
        </div>
      </div>
    </div>
  );
};
