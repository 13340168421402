/* eslint-disable @typescript-eslint/no-unused-vars */
import '@tomtom-international/web-sdk-maps/dist/maps.css';
import tt from '@tomtom-international/web-sdk-maps';
import { LegacyRef, useEffect, useRef, useState } from 'react';
import { RightMapPanel } from '../RightMapPanel/RightMapPanel';
import { useTrucksContext } from '../../context/TrucksProvider';
import { TruckIco } from './TruckIco';
import ReactDOM from 'react-dom';

const API_MAP_KEY = process.env.REACT_APP_API_MAP_KEY as string;

export const LogisticsMap = () => {
  const { trucks, setQuery } = useTrucksContext();


  let middlePositionCoordinates = [ 12, 52 ];

  useEffect(() => {
    setQuery('');
    if (!trucks) {
      return;
    }

    const middlePosition = trucks.reduce((a, v) => {
      if (v.latitude_mdeg && v.longitude_mdeg) {
        a[0] += v.longitude_mdeg / 1000000;
        a[1] += v.latitude_mdeg / 1000000;
        
      }
      return a;
    }, [ 0, 0 ]);
  
    middlePositionCoordinates = [ middlePosition[0] / (trucks.length ? trucks.length : 1), middlePosition[1] / (trucks.length ? trucks.length : 1) ];
    
  }, [ trucks ]);
  // const [ mapLatitude, setMapLatitude ] = useState(37.36765);
  // const [ mapZoom, setMapZoom ] = useState(13);
  const [ map, setMap ] = useState<tt.Map | null>(null);
  const [ markers, setMarkers ] = useState<tt.Marker[]>([]);

  
  const mapElement = useRef<HTMLDivElement>(null);

  // const increaseZoom = () => {
  //   if (mapZoom < MAX_ZOOM) {
  //     setMapZoom(mapZoom + 1);
  //   }
  // };

  // const decreaseZoom = () => {
  //   if (mapZoom > 1) {
  //     setMapZoom(mapZoom - 1);
  //   }
  // };

  // const updateMap = () => {
  //   map.setCenter([ parseFloat(mapLongitude), parseFloat(mapLatitude) ]);
  //   map.setZoom(mapZoom);
  // };

  
  useEffect(() => {
   
    console.log('middle position: ', middlePositionCoordinates);
    const newMap = tt.map({
      key: API_MAP_KEY,
      container: mapElement.current as HTMLDivElement,
      center: [  middlePositionCoordinates[0], middlePositionCoordinates[1] ],
      zoom: 5,
      // style: `https://api.tomtom.com/style/2/custom/style/dG9tdG9tQEBAbWJzSlBmOGY5dWlEYnhEUTtkYjYwOWM1NC03NjkxLTQyNjgtOTM3Yi04OGQzZmFiMzdmMGM=.json?key=${API_MAP_KEY}`,
    });
    setMap(newMap);

    trucks.filter(truck => truck.active).forEach(van => {
      if (van.latitude_mdeg && van.longitude_mdeg) {
        const coordinates = [ (van.longitude_mdeg / 1000000), (van.latitude_mdeg / 1000000) ];
        // console.log(coordinates);

        const markerElement = document.createElement('div');
        const color = !van.ignition
          ? 'black'
          : (van.speed && van.speed > 0 && van.speed <= 115)
            ? 'green'
            : (!!van.standstill || (van.speed && van.speed > 115))
              ? 'red'
              : '#D85930';

        // eslint-disable-next-line react/no-deprecated
        ReactDOM.render((
          <TruckIco color={color}/>
        ), markerElement);

        const marker = new tt.Marker({
          element: markerElement,
          anchor: 'bottom'
        }).setLngLat(coordinates as tt.LngLatLike).addTo(newMap);

  
        const popupOffsets = {
          top: [ 0, 0 ],
          bottom: [ 0, -35 ],
          'bottom-right': [ 0, -70 ],
          'bottom-left': [ 0, -70 ],
          left: [ 25, -35 ],
          right: [ -25, -35 ],
        };
        const date = new Date(van.ignition_time as string);
        const popup = new tt.Popup({ offset: popupOffsets as unknown as tt.PointLike }).setHTML(
          `<h1>${van.objectname}</h1>
          <h2>Fuel: ${van.fuellevel ? van.fuellevel / 10 : 0}%</h2>
          <h2>Speed: ${van.speed}</h2>
          <h2>Ignition: ${van.ignition ? `ON since ${date.toLocaleString()}` : 'OFF'}</h2>`
          
        );
        marker.setPopup(popup);
      }
      
    });
    
    return () => newMap.remove();
  }, [ trucks ]);
  
  

  return (
    <div className='flex flex-row'>

      <div ref={mapElement as LegacyRef<HTMLDivElement> | undefined} className='mapDiv flex-grow' style={{ height: 'calc(100vh - 50px)' }} />
      <div className='min-w-80'>
        <RightMapPanel />
      </div>
      
    </div>

    
  );
};