import { AxiosResponse } from 'axios';
import { apiClient } from '../services/axiosClient';
import { Currency, PriceList, Zone } from '../types/PriceList';
import { Country } from '../types/Countries';


type PriceListsType = {
  priceLists: PriceList[],
  count: number
}

const fetchPriceLists = async () => {
  const data: AxiosResponse<PriceListsType> = await apiClient.get('/price-list');

  if (!data) {
    return;
  }

  return data;
};

const fetchPriceList = async (id: string) => {
  const data: AxiosResponse<PriceList> = await apiClient.get(`/price-list/${id}`);

  if (!data) {
    return;
  }

  return data;
};

const addPriceList = async (data: Partial<PriceList>) => {
  return await apiClient.post('/price-list', data);
};

export type addCountryToPriceListProps = {
  priceListId: string,
  countryId: string
}


const fetchPriceListCountries = async (id: string) => {

  const response: AxiosResponse<Country[]> = await apiClient.get(`/price-list-country/${id}`);
  if (!Array.isArray(response.data)) {
    return [];
  }

  return response.data;
};

const addCountryToPriceList = async (data: addCountryToPriceListProps) => {
  return await apiClient.post('/price-list-country', data);
};


const addCountry = async (data: Partial<Country>) => {
  return await apiClient.post('/country', data);
};

const fetchCurrencies = async () => {
  const data: AxiosResponse<Currency[]> = await apiClient.get('/currency');

  if (!data) {
    return;
  }

  return data;
};

const fetchCountries = async () => {

  const response: AxiosResponse<Country[]> = await apiClient.get('/country');

  if (!response) {
    return;
  }

  return response.data;
};



const fetchZones = async () => {
  const response: AxiosResponse<Zone[]> = await apiClient.get('/zones');

  if (!response.data) {
    return;
  }

  return response.data;
};

const fetchCountryZones = async (countryCode: Pick<Country, 'countryCode'>) => {
  try {
    const response: AxiosResponse<Zone[]> = await apiClient.get(`/country-zones/:${countryCode}`);

    if (!Array.isArray(response.data)) {
      return [];
    }
    return response.data;
  } catch (e) {
    console.log(e);
  }

};

export type addCountryZoneProps = {
  zoneId: string,
  countryCode: string
}

const addCountryZone = async (data: addCountryZoneProps) => {
  console.log(data);
  const response: AxiosResponse<Zone> = await apiClient.post('/country-zones', data);

  if (!response.data) {
    return;
  }

  return response.data;
};

const patchCountry = async (country: Partial<Country>) => {
  const data: AxiosResponse<Country> = await apiClient.patch(`/country/${country.id}`, country);

  if (!data) {
    return;
  }

  return data;
};

const addZone = async (data: Partial<Zone>) => {
  return await apiClient.post('/zones', data);
};

type OrderData = {
  senderCoordinates: Coordinates,
  receiverCoordinates: Coordinates,
  weight: number,
  floorArea: number,
  userId: number
}

type Coordinates = {
  latitude: number,
  longitude: number,
}

export type OrderPriceReturnData = {
  priceInEUR: number,
  distance: number,
  vatRate: string
}

const getOrderPrice = async (orderData: OrderData): Promise<OrderPriceReturnData> => {
  try {
    const { data } = await apiClient.post('get-dist-price', orderData);
    return data;
  } catch (e) {
    throw new Error('Problem getting price');
  }
};

export const priceListServices = {
  getOrderPrice,
  addZone,
  patchCountry,
  addCountryZone,
  fetchCountryZones,
  fetchZones,
  fetchCurrencies,
  fetchCountries,
  addCountry,
  addCountryToPriceList,
  fetchPriceListCountries,
  fetchPriceList,
  addPriceList,
  fetchPriceLists,
};