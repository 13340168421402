import { Order } from '../../../types/Order';
import { useForm, FormProvider } from 'react-hook-form';
import { OrderForm } from './OrderForm';


export const NewOrder = () => {
  const methods = useForm<Order>({
    defaultValues: {
      packages: [
        {
          id: '1',
          type: 'other',
          goods: '',
          width: '',
          length: '',
          height: '',
          weight: '',
          pcs: 1,
          link: '',
          ref: '',
          chairs: false
        }
      ]
    }
  });
  

  return (
    <FormProvider {...methods}>
      <OrderForm/>
    </FormProvider>
  );

};
