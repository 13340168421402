import { apiClient } from '../services/axiosClient';
import { Order } from '../types/Order';
// import { Order } from '../types/Order';

// const baseUrl = process.env.REACT_APP_API_URL as string;

export type OrderResponseData = {
  rows: Order[],
  count: number
}
const getOrders = async (params: string): Promise<OrderResponseData> => {

  try {
    const { data } =  await apiClient.get('/orders?' + params);
    return data;

    
  } catch (error) {
    console.log(error);
    throw new Error('Problem downloading orders');
  }
 
};

const getOrder = async (orderNo: number): Promise<Order> => {
  try {
    const { data } = await apiClient.get(`orders/${orderNo}`);
    console.log('get order: ', data);
    return data;
  } catch (e) {
    console.log(e);
    throw new Error('Error downloading order');
  }
};

const createOrder = async (order: Partial<Order>): Promise<Order> => {
  console.log('creating order');
  try {

    const { data } = await apiClient.post('/orders', order);

    return data;
  } catch (e) {
    console.log(e);
    throw new Error('Error adding new order');
  }
};

type DeleteResponse = {
  message: string
}

const deleteOrder = async (orderId: number): Promise<DeleteResponse> => {
  try {
    const { data } = await apiClient.delete(`/orders/${orderId}`);

    return data;
  } catch (e) {
    throw new Error('Could not delete order');
  }
};

export const ordersService = {
  getOrders,
  getOrder,
  createOrder,
  deleteOrder
};