import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import { Layout } from './Layout';
import { OrdersList } from './components/Orders/OrdersList';
import { NewOrder } from './components/Orders/NewOrder/NewOrder';
import { Invoices } from './components/Invoices/Invoices';
import { Documents } from './components/Documents/Documents';
// import { PriceList } from './components/PriceList/PriceList';
import { Registration } from './components/Registration/Registration';
import { Activation } from './components/Registration/Activation/Activation';
import { PasswordReset } from './components/PasswordReset/PasswordReset';
import { NewPassword } from './components/PasswordReset/NewPassword';
import { EmployeeRegistration } from './components/EmployeeRegistration/EmployeeRegistration';
import { Login } from './components/Login/Login';
import { ProtectedRoute } from './components/ProtectedRoute/ProtectedRoute';
import { ContactsList } from './components/Contacts/ContactsList';
import { NewContact } from './components/Contacts/NewContact';
import { Unathorized } from './components/Unathorized/Unauthorized';
import { OrderDetails } from './components/Orders/OrderDetails';
import { useAuthContext } from './context/AuthProvider';
import { LogisticsMap } from './components/Map/LogisticsMap';
import { PageNotFound } from './components/PageNotFound/PageNotFound';
import { UserPage } from './components/UserPage/UserPage';
import { TrucksList } from './components/TrucksList/TrucksList';
import { SettingsPage } from './components/SettingsPage/SettingsPage';
import { TruckDetailsPage } from './components/TruckDetailsPage/TruckDetailsPage';
import { Clients } from './components/Clients/Clients';
import { Prices } from './components/Prices/Prices';
import { EmployeeLogin } from './components/EmployeeLogin/EmployeeLogin';
import { DriverRoutes } from './components/DriverRoutes/DriverRoutes';
import { CheckoutForm, Return } from './components/Orders/CheckoutForm';
import { PaymentSuccess } from './components/Orders/PaymentSuccess';
import { PaymentFail } from './components/Orders/PaymentFail';
import TermsAndConditions from './components/TermsAndConditions/TermsAndConditions';

export const App = () => {
  const { activeUser } = useAuthContext();
  let navigatoTo;

  switch (activeUser?.role) {
  case 'admin':
    navigatoTo = 'map';
    break;
  case 'logistics':
    navigatoTo = 'map';
    break;
  case 'employee':
    navigatoTo = 'documents';
    break;
  default:
    navigatoTo = 'orders';
  }
  return (<>

    <BrowserRouter >
      <Routes>
        <Route element={<ProtectedRoute allowedRoles={[ 'admin', 'user', 'logistics', 'employee' ]} />}>
          <Route path='/' element={<Layout />}>
            <Route index element={<Navigate to={navigatoTo} />} />
            <Route element={<ProtectedRoute allowedRoles={[ 'admin', 'logistics' ]} />}>
              <Route path='/map' element={<LogisticsMap />} />
              <Route path='/vans' element={<TrucksList />} />
              <Route path='/vans/:truckId' element={<TruckDetailsPage />} />
              <Route path='/clients' element={<Clients />} />
              <Route path='/contacts' element={<ContactsList />} />
            </Route>
            <Route element={<ProtectedRoute allowedRoles={[ 'admin' ]} />}>
              <Route path='/settings' element={<SettingsPage />} >
                <Route index element={<Navigate to={'countries'}/>} />
                {/* <Route path='countries' element={<Countries />} />
                <Route path='zones' element={<Zones />} />
                <Route path='countries-zones' element={<CountriesAndZones/>} />
                <Route path='countries-zones/:id' element={<CountriesAndZones/>} >
                  <Route index element={<Navigate to={'country-zones'}/>} />
                  <Route path='country-zones' element={<CountryZones/>} />
                </Route> */}
              </Route>
              {/* <Route path='/price-list' element={<PriceList />} />
              <Route path='/price-list/:id' element={<PriceListDetails />} >
                <Route index element={<Navigate to={'pricelist-countries'}/>} />
                <Route path='pricelist-countries' element={<PriceListCountries/>} /> */}
              <Route path='prices' element={<Prices/>} />
              {/* </Route> */}
            </Route>
            <Route element={<ProtectedRoute allowedRoles={[ 'user', 'admin', 'logistics' ]} />}>
              <Route path='orders' element={<OrdersList />} />
              <Route path='new-order' element={<NewOrder />} />
              <Route path='new-contact' element={<NewContact />} />
              <Route path='invoices' element={<Invoices />} />
              <Route path='checkout' element={<PaymentFail />} />

              <Route path='checkout/:orderNo' element={<CheckoutForm />} />
              <Route path='return' element={<Return />} />
             
              {/* <Route path='price-calc' element={<CalculatePrice />} />
              <Route path='countries-zones' element={<CountriesAndZones />} /> */}
              <Route path='orders/:orderNo' element={<OrderDetails />} />
              <Route path='success' element={<PaymentSuccess />} />
            </Route>
            <Route element={<ProtectedRoute allowedRoles={[ 'admin', 'logistics', 'employee' ]} /> } >
              <Route path='routes' element={<DriverRoutes/>} />
            </Route>
            <Route path='documents' element={<Documents />} />
            <Route path='profile' element={<UserPage />} />

            <Route element={<ProtectedRoute allowedRoles={[ 'user' ]} />} >
              <Route path='terms' element={<TermsAndConditions/>} />
            </Route>

          </Route>
        </Route>


        {/* <Route path='/newmap' element={<LogisticsMap />} /> */}
        <Route path='/login' element={<Login />} />
        <Route path='/registration' element={<Registration />} />

        <Route path='/employee-login' element={<EmployeeLogin />} />
        <Route path='/employee-registration' element={<EmployeeRegistration />} />

        <Route path='/activate/:activationToken' element={<Activation />} />
        <Route path='/setnewpass/:passToken' element={<NewPassword />} />

        <Route path='/passwordReset' element={<PasswordReset />} />
        <Route path='/unauthorized' element={<Unathorized />} />
        <Route path='*' element={<PageNotFound />} />
      </Routes>
    </BrowserRouter>
  </>
  );
};
