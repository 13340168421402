import {  useFormContext } from 'react-hook-form';
import { AutoFillClients } from './AutoFillClients';
import { SenderContact } from './SenderContact';
import { useAuthContext } from '../../../context/AuthProvider';
import { ReceiverContact } from './ReceiverContact';
import { Packages } from './Packages';
import { Order } from '../../../types/Order';
import { useAddOrder } from '../../../hooks/useOrders';
import { useNavigate } from 'react-router-dom';
import cn from 'classnames';
import { priceListServices } from '../../../api/priceLists';
import { useState } from 'react';


export const OrderForm = () => {
  const [ isLoading, setIsLoading ] = useState<boolean>(false);
  const { handleSubmit, watch } = useFormContext();
  const { activeUser } = useAuthContext();
  const { addOrder } = useAddOrder();
  const [ price, setPrice ] = useState<number | undefined>(undefined);
  const [ distance, setDisctance ] = useState<number | undefined>(undefined);
  const [ vatRate, setVatRate ] = useState<string>('');
  const [ orderDataToast, setOrderDataToast ] = useState<boolean>(false);

  const navigate = useNavigate();

  const getPrice = async () => {
    if (!activeUser) {
      return;
    }
    const userId = activeUser.id;
    const senderCoordinates = watch('sender.coordinates');
    const receiverCoordinates = watch('receiver.coordinates');
    const packages = watch('packages');
    const weight = packages.reduce((acc: number, pack: { weight: number; }) => acc + +pack.weight, 0);
    const floorArea = packages.reduce((acc: number, pack: {width: number, length: number}) => acc + (+pack.width * +pack.length), 0);
    if (!senderCoordinates.lon || !receiverCoordinates.lon || !weight || !floorArea) {
      setOrderDataToast(true);
      setTimeout(() => {
        setOrderDataToast(false);
      }, 2000);
      return;
    }

    setIsLoading(true);
    try {
      const data = await priceListServices.getOrderPrice({ senderCoordinates, receiverCoordinates, weight, floorArea, userId });
      setDisctance(data.distance);
      setVatRate(data.vatRate);
      setPrice(data.priceInEUR);
    } catch (e) {
      console.log('problem getting price');
    } finally {
      setIsLoading(false);
    }
    
  };
 
  const onSubmit = async (data: Partial<Order>) => {
    try {
      await addOrder(data, {
        onSuccess: (order) => {
          navigate(`/checkout/${order.id}`);
        }
      });
      // navigate('/orders');
      
    } catch (e) {
      console.log('problemm adding order');
    } 
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className='space-y-12'>
        {activeUser && (activeUser.role === 'admin' || activeUser.role === 'logistics') && 
          <AutoFillClients/>
        }
   
        <SenderContact/>

        <ReceiverContact />

        <Packages />

      </div>
  
      <div className='pb-3'>
        <div className='mt-3 flex items-center justify-between gap-x-6'>

          <button
            type='button'
            onClick={() => getPrice()}
            className={cn('rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 bg-orange-600 focus-visible:outline-orange-600 hover:bg-orange-600',{
            })}
          >
            {isLoading ? 'checking best price' : 'Get price'}
          </button>
          {price && `Price: ${vatRate === '23%' ? price * 1.23 : price}€ | distance: ${distance}km`}
          {orderDataToast && 'Please fill order data'}
          <div className=' flex items-center justify-end gap-x-6'>
            <button
              type='button'
              className='text-sm font-semibold leading-6 text-gray-900'
            >
      Cancel
            </button>

            <button
              type='submit'
              className='rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 bg-orange-600 focus-visible:outline-orange-600 hover:bg-orange-600'
            >
        Save
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};