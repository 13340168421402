import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { verifyToken } from '../../../api/activation';
import { ExclamationTriangleIcon, CheckCircleIcon, XCircleIcon } from '@heroicons/react/20/solid';



export const Activation = () => {
  const [ activationStatus, setActivationStatus ] = useState<string>('Verifying...');
  const { activationToken } = useParams();
  
  useEffect(() => {
    const checkActivation = async () => {
      if (activationToken) {
        try {
          const statusMessage = await verifyToken(activationToken);
          setActivationStatus(statusMessage);
          // console.log(statusMessage);
        } catch (error) {
          setActivationStatus('Error occurred while verifying token.');
        }
      } else {
        setActivationStatus('Invalid or missing activation token.');
      }
    };

    checkActivation();
  }, [ activationToken ]); // Dependency on activationToken

  return (
    <>
      {(activationStatus === 'Verifying...') && 

        <div className="rounded-md bg-yellow-50 p-4 mt-20 mx-20">
          <div className="flex justify-center">
            <div className="flex-shrink-0">
              <ExclamationTriangleIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
            </div>
            <div className="ml-3">
              <h3 className="text-sm font-medium text-yellow-800">Account Activation</h3>
              <div className="mt-2 text-sm text-yellow-700">
                <h1>Account Activation</h1>
                <p>{activationStatus}</p>
              </div>
            </div>
          </div>
        </div>
      }

      {activationStatus === 'Account Activated' &&
      <div className="rounded-md bg-green-50 p-4 mt-20 mx-20">
        <div className="flex justify-center">
          <div className="flex-shrink-0">
            <CheckCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" />
          </div>
          <div className="ml-3">
            <p className="text-sm font-medium text-green-800">{activationStatus}</p>
          </div>
          {/* <div className="ml-auto pl-3">
          <div className="-mx-1.5 -my-1.5">
            <button
              type="button"
              className="inline-flex rounded-md bg-green-50 p-1.5 text-green-500 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2 focus:ring-offset-green-50"
            >
              <span className="sr-only">Dismiss</span>
              <XMarkIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </div>
        </div> */}
        </div>
      </div>
      }

      {(activationStatus !== 'Verifying...' && activationStatus !== 'Account Activated') && 
    
    <div className="rounded-md bg-red-50 p-4 mt-20 mx-20">
      <div className="flex justify-center">
        <div className="flex-shrink-0">
          <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
        </div>
        <div className="ml-3 text-center">
          <h3 className="text-sm font-medium text-red-800">There was problem with activation of your account.</h3>
          <h3 className="text-sm font-medium text-red-800">Please try again later</h3>
          {/* <div className="mt-2 text-sm text-red-700">
          <ul role="list" className="list-disc space-y-1 pl-5">
            <li>Your password must be at least 8 characters</li>
            <li>Your password must include at least one pro wrestling finishing move</li>
          </ul>
        </div> */}
        </div>
      </div>
    </div>
      }
    </>
  );
};
